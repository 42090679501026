import {
  IonCheckbox,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { personCircle } from "ionicons/icons";
import { Link, useHistory } from "react-router-dom";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import { saveUser } from "../database/userDatabaseService";
import { apiConfig } from "../configs/apiconfig";
import { set } from "../data/IonicStorage";
import {
  Facebook,
  Twitter,
  Mail,
  GitHub,
  HelpCircle,
  Coffee,
} from "react-feather";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
  Button,
  CardText,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap";
import InputPasswordToggle from "../components/input-password-toggle";
import "../@core/scss/react/pages/page-authentication.scss";

function validateEmail(email) {
  // eslint-disable-next-line no-control-regex
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}
const Login = function () {
  const history = useHistory();
  const [email, setEmail] = useState("mehmet.yonugul@adverti.com.tr");
  const [password, setPassword] = useState("123456*");
  const [rememberMe, setRememberMe] = useState(true);
  const [iserror, setIserror] = useState(false);
  const [message, setMessage] = useState("");
  console.log("Login");
  const handleLogin = () => {
    if (!email) {
      setMessage("Email Adresi Geçersiz. Lütfen Geçerli bir email hesabı giriniz.");
      setIserror(true);
      return;
    }
    if (validateEmail(email) === false) {
      setMessage("Email Adresi Bulunamadı.");
      setIserror(true);
      return;
    }

    if (!password || password.length < 6) {
      setMessage("Şifre eksik veya hatalı");
      setIserror(true);
      return;
    }
    var config = {
      method: "post",
      url: "/login",
      baseURL: apiConfig.apiAdress,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: `username=${email}&password=${password}&rememberme=${rememberMe}&grant_type=password`,
    };
    axios(config)
      .then((res) => {
        saveUser(
          res.data.user_mail,
          res.data.user_title,
          res.data.access_token,
          res.data.refresh_token
        ).then((result) => {
          set("access_token", res.data.access_token);
          set("refresh_token", res.data.refresh_token);
          history.push("/books");
        });
      })
      .catch((error) => {
        setMessage("Auth failure! Please create an account");
        setIserror(true);
      });
  };

  return (
    <IonPage>
      <IonRow>
        <IonCol>
          <IonAlert
            isOpen={iserror}
            onDidDismiss={() => setIserror(false)}
            cssClass="my-custom-class"
            header={"Error!"}
            message={message}
            buttons={["Dismiss"]}
          />
        </IonCol>
      </IonRow>
      <div className="auth-wrapper auth-basic px-2">
        <div className="auth-inner my-2">
          <Card className="mb-0">
            <CardBody>
              <Link
                className="brand-logo"
                to="/"
                onClick={(e) => e.preventDefault()}
              >
                <img src="/assets/logo.png" width={"200px"} alt="logo" />
              </Link>
              <h4 className="text-center font-bold">
                Palme Kurumsal Kütüphanem
              </h4>
              <CardText className="mb-2">
                https://kurumsalbasariseti.palmeyayinevi.com/ adresi üzerinde
                tanımlı hesabınız ile giriş yapabilirsiniz.
              </CardText>
              <Form
                className="auth-login-form mt-2"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="mb-1">
                  <Label className="form-label" for="login-email">
                    E-Posta Adresiniz
                  </Label>
                  <Input
                    type="email"
                    id="login-email"
                    value={email}
                    onChange={(e) => setEmail(e.value)}
                    placeholder="email@kurum.com"
                    autoFocus
                  />
                </div>
                <div className="mb-1">
                  <div className="d-flex justify-content-between">
                    <Label className="form-label" for="login-password">
                      Şifreniz
                    </Label>
                  </div>
                  <InputPasswordToggle
                    className="input-group-merge"
                    id="login-password"
                    value={password}
                    onChange={(e) => setPassword(e.value)}
                  />
                </div>
                <div className="form-check mb-1">
                  <Input type="checkbox" id="remember-me"  />
                  <Label className="form-check-label" for="remember-me">
                    Beni Hatırla
                  </Label>
                </div>
                <Button color="primary" onClick={handleLogin} block>
                  Giriş Yap
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    </IonPage>
  );
};

export default React.memo(Login);
