import { IonRadio, IonRadioGroup } from "@ionic/react";
import { useDispatch } from "react-redux";
import { Row, Col, Input, Label } from "reactstrap";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";

const MobileFilters = (props) => {
  const { dispatch, setFilterSpec, store } = props;

  const filterBook = ({ lessonId, classId, status }) => {
    if (lessonId !== undefined) {
      dispatch(setFilterSpec({ ...store.filterSpec, lessonId }));
    }
    if (classId !== undefined) {
      dispatch(setFilterSpec({ ...store.filterSpec, classId }));
    }
    if (status !== undefined) {
      dispatch(setFilterSpec({ ...store.filterSpec, status }));
    }
  };
  return (
    <>
      <div className="mobile-filters">
        <div className="brands">
        <Row>
            <Col>
              <IonList>
                <IonItem>
                  <IonSelect
                    placeholder="Sınıf Seçimi"
                    onIonChange={(e) => filterBook({ classId: e.detail.value })}
                  >
                    {store.classes.map((c) => {
                      return (
                        <IonSelectOption value={c.id}>
                          {c.title}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonList>
            </Col>
            <Col>
              <IonList>
                <IonItem>
                  <IonSelect
                    placeholder="Ders Seçimi"
                    onIonChange={(e) =>
                      filterBook({ lessonId: e.detail.value })
                    }
                  >
                    {store.lessons.map((l) => {
                      return (
                        <IonSelectOption value={l.id}>
                          {l.title}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              </IonList>
            </Col>
          </Row>
          <ul className="list-unstyled categories-list d-flex justify-content-center mt-1">
            <li>
              <div className="form-check">
                <Input
                  type="radio"
                  id="all"
                  name="status-radio"
                  onChange={() => filterBook({ status: null })}
                />
                <Label className="form-check-label" for="all">
                  Tümü
                </Label>
              </div>
            </li>
            <li className="ms-1">
              <div className="form-check">
                <Input
                  type="radio"
                  id="downloaded"
                  name="status-radio"
                  onChange={() => filterBook({ status: 1 })}
                />
                <Label className="form-check-label" for="downloaded">
                  İndirilenler
                </Label>
              </div>
            </li>
          </ul>
    
        </div>
      </div>
    </>
  );
};

export default MobileFilters;
