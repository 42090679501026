
import 'animate.css/animate.css';
import Swal from 'sweetalert2';
import AddNote from './AddNote';
import ImageWindow from './ImageWindow';
import SoundWindow from './SoundWindow';
import VideoWindow from './VideoWindow';

const ShowMediaItem = (props) => {
  const { item, mediaItems, smartBookLayout, dispatch, setUserItems, bookId } =
    props;
  const onWindowClose = () => {
    let items = [...mediaItems];
    items = items.map((p) => (p.id === item.id ? { ...p, IsOpen: false } : p));
    dispatch(setUserItems(items));
  };
  const deleteUserItem = (id) => {
    Swal.fire({
      title: 'İçerik Silinecek',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
      showLoaderOnConfirm: true,
      preConfirm: (text) => {
        window.electron.deleteUserItem(id).then(() => {
          window.electron.getUserItems(bookId).then((items) => {
            dispatch(setUserItems(items));
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('İçerik Silindi', 'İçerik başarıyla kaydedildi.', 'success');
      }
    });
  };
  return (
    <>
      {item.type === 30 && (
        <ImageWindow
          deleteUserItem={deleteUserItem}
          onWindowClose={onWindowClose}
          filePath={item.filePath ? item.filePath : item.activityLink}
        />
      )}
      {item.type === 40 && (
        <SoundWindow
          deleteUserItem={deleteUserItem}
          onWindowClose={onWindowClose}
          filePath={item.filePath ? item.filePath : item.activityLink}
        />
      )}
      {item.type === 50 && (
        <VideoWindow
          deleteUserItem={deleteUserItem}
          onWindowClose={onWindowClose}
          filePath={item.filePath ? item.filePath : item.activityLink}
        />
      )}
      {item.type === 20 && (
        <AddNote
          id={item.id}
          smartBookLayout={smartBookLayout}
          deleteUserItem={deleteUserItem}
          userItem={item}
          onUserItemSave={console.log('test')}
          onNoteWindowClose={onWindowClose}
        />
      )}

    </>
  );
};
export default ShowMediaItem;
