export default {
  Circle: "circle",
  Line: "line",
  Arrow: "arrow",
  Pencil: "pencil",
  Marker: "marker",
  Eraser: "eraser",
  Text: "text",
  Rectangle: "rectangle",
  Triangle: "triangle",
  Polygon: "polygon",
  RectangleLabel: "rectangle-label",
  Select: "select",
  Pan: "pan",
  Highlighter: "highlighter",
  DefaultTool: "default-tool"
}
