
import { FaRegFile, FaTimes } from 'react-icons/fa';
import { HiOutlinePresentationChartLine } from 'react-icons/hi';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';

const DisplayType = ({
  dispatch,
  isPageFullWidth,
  handlePageIsFullWidth,
  displayType,
  setDisplayType,
  setCurrentPageItem,
  currentPage,
  currentPageItems,
  setUserPage
}) => {

  return (
    <>
      {displayType === 'page' && (
        <div
          className="tool-item"
          onClick={() => dispatch(handlePageIsFullWidth(!isPageFullWidth))}
        >
          {isPageFullWidth ? (

            <MdFullscreenExit />
          ) : (
            <MdFullscreen/>
          )}
          <span>{isPageFullWidth ? 'Sığdır' : 'Genişlet'}</span>
        </div>
      )}

 
    </>
  );
};

export default DisplayType;
