const SizeToolItem = ({ size, onClick }) => {
  return (
    <li
      className="size-box border"
      style={{ backgroundColor: "black", width: '25%'
 }}
      onClick={() => onClick()}
    >
      <span>{size}</span>
    </li>
  )
}

export default SizeToolItem
