import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Button, Spinner, Input } from "reactstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "../../utility/axios";
import { setBooks, setFilterSpec } from "./store";
import { apiConfig } from "../../configs/apiconfig";
import { useHistory } from "react-router-dom";
import {
  saveBooks,
  getAllBooks,
  saveBook,
  getBookPages,
  setBookDownloadedStatus,
  deleteBook,
  saveBookUnits,
  saveBookTests,
} from "../../database/bookDatabaseService";
import BookCard from "./components/BookCard";
import Sidebar from "./SideBar";
import "./index.scss";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { book } from "ionicons/icons";
import { saveBookPage, saveOptionImage } from "../../data/IonicStorage";
import { SavePageItems } from "../../database/pageItemDatabaseService";
import { saveClasses } from "../../database/classDatabaseService";
import { saveLessons } from "../../database/lessonDatabaseService";
import UILoader from "../../components/ui-loader";
import MobileFilters from "./MobileFilters";
const Books = () => {
  const dispatch = useDispatch();
  const bookStore = useSelector((state) => state.bookStore);
  const [downloadedBookId, setDownloadedBookId] = useState();
  const [bookPages, setBookPages] = useState([]);
  const [totalBookPage, setTotalBookPage] = useState(0);
  const [completedPage, setCompletedPage] = useState(0);
  const [isDownloading, setIsDownloading] = useState(0);
  let progress = 0;
  const history = useHistory();

  useIonViewWillEnter(async () => {
    axios.get(apiConfig.getBooks).then((res) => {
      saveBooks(res.data).then(() => {
        saveClasses(res.data.classList).then(() => {
          saveLessons(res.data.lessonList).then(() => {
            getBooks();
          });
        });
      });
    });
  });

  const getBooks = () => {
    getAllBooks().then((res) => {
      dispatch(
        setBooks({
          books: res.books.values,
          classes: res.classes.values,
          lessons: res.lessons.values,
        })
      );
    });
  };

  const deleteSelectedBook = async (bookId) => {
    await deleteBook(bookId);
    getBooks();
  };

  useEffect(() => {
    getBooks();
  }, [bookStore.filterSpec]);

  useEffect(() => {
    const pageItems = [];
    if (completedPage === totalBookPage && completedPage !== 0) {
      bookPages.map((page) => {
        saveBookPage(page.bookId, page.id, page.pageImage);
        page.pageItems.map((item) => {
          pageItems.push({ ...item, pageId: page.id, pageNo: page.pageNo });
          item.options.map((option) => {
            pageItems.push({
              ...option,
              pageId: page.id,
              pageNo: page.pageNo,
              parentId: item.id,
            });
            saveOptionImage(item.id, option.id, option.imageBytes);
          });
        });
      });
      SavePageItems(pageItems).then((result) => {
        setBookDownloadedStatus(downloadedBookId).then((res) => {
          setCompletedPage(0);
          setTotalBookPage(0);
          setIsDownloading(false);
          getBooks();
        });
      });
    }
  }, [completedPage]);

  const downloadBookPage = async (bookId, pageNo) => {
    await axios
      .get(apiConfig.downloadBookPage, {
        params: { bookId, pageNumber: pageNo },
      })
      .then((response) => {
        const pages = bookPages;
        pages.push({
          pageImage: response.data.pageImage,
          pageNo,
          id: response.data.id,
          bookId,
          pageItems: response.data.pageItems,
        });
        setBookPages(pages);
        progress += 1;
        setCompletedPage(progress);
      });
  };

  const downloadBook = async (bookId) => {
    setDownloadedBookId(bookId);
    setIsDownloading(true);
    // setDownloadedBookId(bookId);
    // setIsDownloading(true);
    var response = await axios.get(apiConfig.downloadBook, {
      params: { bookId },
    });
    await saveBook(response.data);
    await saveBookUnits(response.data);
    await saveBookTests(response.data);
    
    var pages = await getBookPages(bookId);
    setTotalBookPage(pages.length);
    pages.map((page) => downloadBookPage(bookId, page.pageNo));
  };

  const renderBooks = () => {
    if (bookStore.books) {
      return bookStore.books.map((book, index) => {
        return (
          <BookCard
            key={index}
            book={book}
            dispatch={dispatch}
            deleteBook={deleteSelectedBook}
            downloadBook={downloadBook}
          />
        );
      });
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Palme Kurumsal Kütüphanem</IonTitle>
          </IonToolbar>
        </IonHeader>
        <UILoader
          blocking={isDownloading}
          loader={
            <Button color="primary">
              <Spinner color="white" size="sm" />
              <span className="ms-50">Kitap İndiriliyor...</span>
            </Button>
          }
          className="h-100 loader-class"
          overlayColor="rgba(115, 103, 240, .1)"
        >
          <div className="book-list">
            <div className="content-detached content-right">
              <div className="content-body">
                <Row>
                  {completedPage !== 0 && (
                    <ProgressBar
                      completed={`${completedPage}/${totalBookPage}`}
                      maxCompleted={totalBookPage}
                      height="30px"
                      completedClassName="barCompleted"
                    />
                  )}
                </Row>
                <MobileFilters
                  dispatch={dispatch}
                  setFilterSpec={setFilterSpec}
                  store={bookStore}
                ></MobileFilters>
                <Input
                  placeholder="Kitap Adı"
                  onChange={(e) =>
                    dispatch(
                      setFilterSpec({
                        ...bookStore.filterSpec,
                        title: e.target.value,
                      })
                    )
                  }
                ></Input>
                <div className="grid-view">
                  {bookStore.books && renderBooks()}
                </div>
              </div>
            </div>
            <Sidebar
              store={bookStore}
              dispatch={dispatch}
              setFilterSpec={setFilterSpec}
            />
          </div>
        </UILoader>
      </IonContent>
    </IonPage>
  );
};

export default Books;
