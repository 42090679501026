import {
  FaChevronLeft,
  FaChevronRight,
  FaTimesCircle,
  FaRegFile
} from "react-icons/fa";
import { BsQuestionDiamond } from "react-icons/bs";
import { PopoverBody, PopoverHeader, UncontrolledPopover, Input} from "reactstrap";
import { getUserPages } from "../../../../database/userPageDatabase";
import { setDisplayType, setUserPage, setCurrentPageItem } from "../../store";
import { HiOutlinePresentationChartLine } from "react-icons/hi";

import Switch from "react-switch";
import { useEffect, useState } from "react";

const PageNagation = ({
  dispatch,
  next,
  previous,
  showAllGapFillingAnswer,
  store,
}) => {
  const [showGapFillingAnswer, setShowGapFillingAnswer] = useState(true);

  useEffect(()=> {
    setShowGapFillingAnswer(true);
  }, [store.currentPageItem])
  const onDisplayTypeChange = (state) => {
    if (state) {
      const pageItem = store.currentPageItems.pageItems.reduce(function (
        prev,
        curr
      ) {
        return prev.displayOrder < curr.displayOrder ? prev : curr;
      });
      dispatch(setCurrentPageItem(pageItem));
      dispatch(setDisplayType("pageItem"));
    } else {
      dispatch(setDisplayType("page"));
    }
  };

  const previousPage = () => {
    if (store.displayType !== "blankPage") {
      previous();
    } else {
      getUserPages(store.book.id).then((res) => {
        const index = res.findIndex((x) => x.id === store.userPage.id);
        const page = res[index - 1];
        if (page) {
          dispatch(setUserPage(page));
        }
      });
    }
  };

  const nextPage = () => {
    if (store.displayType !== "blankPage") {
      next();
    } else {
      getUserPages(store.book.id).then((res) => {
        const index = res.findIndex((x) => x.id === store.userPage.id);
        const page = res[index + 1];
        if (page) {
          dispatch(setUserPage(page));
        }
      });
    }
  };

  const renderShowAnswerButton = () => {
    if(store.currentPageItem.pageItemType===0){
      return      <>
      <div className="tool-item" id="popTop">
        <BsQuestionDiamond />
      </div>
      <UncontrolledPopover placement="top" target="popTop">
        <PopoverHeader>
          {store.currentPageItem.displayOrder}. Soru
        </PopoverHeader>
        <PopoverBody>
          Doğru Cevap: {store.currentPageItem.correctAnswer}
        </PopoverBody>
      </UncontrolledPopover>
    </>
    } else if(store.currentPageItem.pageItemType === 20){
        return  <>
        <div
          className="tool-item"
          onClick={() => {
            showAllGapFillingAnswer(showGapFillingAnswer);
            setShowGapFillingAnswer(!showGapFillingAnswer);
          }}
        >
          <BsQuestionDiamond />
        </div>
      </>
    }
  }

  return (
    <>
         {store.displayType !== 'blankPage' && (
        <div
          className="tool-item"
          onClick={() => {
            dispatch(setUserPage(null))
            dispatch(setDisplayType('blankPage'));
          }}
        >
          <FaRegFile/>
          <span>Boş Sayfa</span>
        </div>
      )}
      <div className="tool-item mobile-close-presentation">
        <Switch
          onColor="#EA172D"
          offColor="#199FB4"
          checkedIcon={<FaTimesCircle fill="#FFFFFF" />}
          uncheckedIcon={<HiOutlinePresentationChartLine color="white" />}
          onChange={(e) => onDisplayTypeChange(e)}
          checked={(store.displayType === "pageItem" || store.displayType==="blankPage") ?? true}
        />
      </div>
      <div className="tool-item" onClick={() => previousPage()}>
        <FaChevronLeft />
      </div>
      {store.displayType === "page" && (
        <Input
          className="page-navigator-input text-center"
          readOnly
          value={`${store.currentPage.pageNo}/${store.book.pages.length}`}
        />
      )}
      <div className="tool-item ms-25" onClick={() => nextPage()}>
        <FaChevronRight />
      </div>
      {store.displayType === "pageItem" && renderShowAnswerButton()
       }
    </>
  );
};

export default PageNagation;
