// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "../../../utility/axios";
import { getBookById } from "../../../database/bookDatabaseService";
import { getUserPages } from "../../../database/userPageDatabase";

export const getBook = createAsyncThunk("smartBook/getBook", async (params) => {
  var book = getBookById(params.bookId);

  return book.data;
});

export const getBookPage = createAsyncThunk(
  "smartBook/getBookPage",
  async (params) => {
    const response = await axios.get(
      `/smartbookmanager/SbPublic/GetPageItems`,
      { params }
    );
    return response.data;
  }
);

export const smartBookSlice = createSlice({
  name: "smartBook",
  initialState: {
    book: {},
    contensInfo: "",
    pages: [],
    userItems: [],
    userPage: {},
    userSettings: {
      userId: 0,
      showContentInfo: false,
      pageItemPosition: "left",
      pageItemIsFull: true,
      toolBoxSize: 1,
      colors: [
        "#000000",
        "#FF0000",
        "#0000FF",
        "#00FF00",
        "#FFCC00",
        "#FFC0CB",
      ],
      thickness: [3, 5, 7, 8],
    },
    currentTest: {},
    currentPageNumber: 1,
    currentPage: null,
    currentPageItems: {
      pageItems: [],
      pageImage: null,
      pageId: 0,
    },
    currentPageItem: null,
    displayType: "page",
    bookName: "",
    bookOptions: {
      pagingOptions: {
        sortClause: "decs",
        orderBy: "id",
        pageIndex: 0,
        pageSize: 5,
      },
      filterSpec: {
        title: "",
        isbn: "",
        classIds: [],
        lessonIds: [],
      },
    },
  },
  reducers: {
    setBook: (state, action) => {
      state.book = action.payload;
      state.currentPage = action.payload.pages[0];
    },
    setUserSettings: (state, action) => {
      state.userSettings = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setContentInfo: (state, action) => {
      let title = "";
      var unit = state.book.units.find(
        (x) => x.startPage <= action.payload && x.endPage >= action.payload
      );
      if (unit) {
        title = unit.name;
        const test = unit.unitContents.find(
          (x) => x.startPage <= action.payload && x.endPage >= action.payload
        );
        if (test) {
          title += "/" + test.name;
        }
      }
      state.contentInfo = title;
    },
    setCurrentPageItems: (state, action) => {
      state.currentPageItems = action.payload;
    },
    setCurrentPageItem: (state, action) => {
      state.currentPageItem = action.payload;
      state.displayType = "pageItem";
    },
    next: (state, action) => {
      if (state.displayType === "page") {
        var bookPages = state.book.pages;
        var nextPage = bookPages.find(
          (x) => x.pageNo === state.currentPage.pageNo + 1
        );
        state.currentPage = nextPage;
      } else if (state.displayType === "pageItem") {
        const pageItems = state.currentPageItems.pageItems.filter(
          (x) => x.displayOrder > state.currentPageItem.displayOrder
        );
        if (pageItems) {
          const pageItem = pageItems.reduce(function (prev, curr) {
            return prev.displayOrder < curr.displayOrder ? prev : curr;
          });
          state.currentPageItem = pageItem;
        }
      }
    },
    previous: (state, action) => {
      if (state.displayType === "page") {
        var bookPages = state.book.pages;
        var previousPage = bookPages.find(
          (x) => x.pageNo === state.currentPage.pageNo - 1
        );
        state.currentPage = previousPage;
      } else if (state.displayType === "pageItem") {
        const pageItems = state.currentPageItems.pageItems.filter(
          (x) => x.displayOrder < state.currentPageItem.displayOrder
        );
        if (pageItems) {
          const pageItem = pageItems.reduce(function (prev, curr) {
            return prev.displayOrder > curr.displayOrder ? prev : curr;
          });
          state.currentPageItem = pageItem;
        }
      }
    },
    setCurrentTest: (state, action) => {
      state.userPage = action.payload;
    },
    setUserPage: (state, action) => {
      state.userPage = action.payload;
    },
    setPageItem: (state, action) => {
      const pageItem = state.currentPageItems.pageItems[action.payload];
      state.currentPageItem = pageItem;
      state.displayType = "pageItem";
    },
    closeTestScreen: (state) => {
      state.showPageItem = true;
      state.currentPageItem = null;
    },
    setDisplayType: (state, action) => {
      state.displayType = action.payload;
    },
    setUserItems: (state, action) => {
      state.displayType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBook.fulfilled, (state, action) => {
      state.book = action.payload;
      state.currentPage = action.payload.selectedPage;
    });
    builder.addCase(getBookPage.fulfilled, (state, action) => {
      state.currentPage = {
        ...action.payload,
        previousPageId: state.currentPage.id,
      };
    });
  },
});
export const {
  setBook,
  setUserSettings,
  setContentInfo,
  setPageItem,
  setPageItems,
  setCurrentPageItems,
  setCurrentPageItem,
  next,
  previous,
  setUserPage,
  setCurrentTest,
  setCurrentPage,
  setUserItems,
  setDisplayType,
  closeTestScreen,
} = smartBookSlice.actions;
export default smartBookSlice.reducer;
