import { Card, CardBody } from 'reactstrap';
import PixeBayImage from './components/Image';

const PixabayResult = ({ searchResults }) => {
  return (
    <>
      {searchResults.data.hits.map((item) => (
        <PixeBayImage item={item} />
      ))}


    </>
  );
};

export default PixabayResult;
