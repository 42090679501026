/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const smartBookLayoutSlice = createSlice({
  name: 'smartBookLayout',
  initialState: {
    logo: '',
    toolboxType: 'vertical',
    toolBoxSize: 1,
    showContentInfo: true,
    isMobileToolActive: false,
    isPageFullWidth: false,
    pageItemPosition: 'left',
    pageItemIsFull: true,
    paperType: '',
    sketchCanvas: null,
    navbarColor: 'black',
    isLoading: false,
    zoomState: { pageScale: 1, scale: 1, zoomPercent: 100, zoomFactor: 1 },
    addText: 1,
    colors: ['#000000', '#FF0000', '#0000FF', '#00FF00', '#FFCC00', '#FFC0CB'],
    thicknesses: [3, 5, 7, 8],
    currentTool: 'pencil',
    currentLineColor: '#0000FF',
    currentThickness: 5,
    isBottomToolOpen: true,
  },
  reducers: {
    setSetting: (state, action) => {
      state.logo = action.payload.logo ? action.payload.logo : "";
      state.showContentInfo = action.payload.showContentInfo;
      state.pageItemPosition = action.payload.pageItemPosition;
      state.pageItemIsFull = action.payload.pageItemIsFull;
      state.currentLineColor = action.payload.colors[0].value;
      state.currentThickness = parseInt(
        action.payload.thicknesses[0].value,
        10
      );
      state.toolBoxSize = action.payload.toolSize;
      state.colors = action.payload.colors;
      state.thicknesses = action.payload.thicknesses;
    },
    setIsMobileToolActive: (state, action) => {
      state.isMobileToolActive = action.payload;
    },
    handleToolBoxType: (state, action) => {
      state.toolboxType = action.payload;
    },
    handleNavbarType: (state, action) => {
      state.navbarType = action.payload;
    },
    handlePageIsFullWidth: (state, action) => {
      state.isPageFullWidth = action.payload;
    },
    handlePageItemSize: (state, action) => {
      state.pageItemIsFull = action.payload;
    },
    handlePageItemPosition: (state, action) => {
      state.pageItemPosition = action.payload;
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload;
    },
    handleNavbarColor: (state, action) => {
      state.navbarColor = action.payload;
    },
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload;
    },
    handleRouterTransition: (state, action) => {
      state.routerTransition = action.payload;
    },
    handleZoom: (state, action) => {
      state.zoomState = action.payload;
    },

    handleAddText: (state) => {
      state.addText++;
    },
    setTool: (state, action) => {
      state.currentTool = action.payload;
    },
    setLineColor: (state, action) => {
      state.currentLineColor = action.payload;
    },
    setThickness: (state, action) => {
      state.currentThickness = action.payload;
    },
    collapseBottomTool: (state) => {
      state.isBottomToolOpen = !state.isBottomToolOpen;
    },
    setPaperType: (state, action) => {
      state.paperType = action.payload;
    }
  },
});

export const {
  setSetting,
  handleRTL,
  handleSkin,
  handleLayout,
  handleRequestLoading,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handlePageIsFullWidth,
  handlePageItemSize,
  handlePageItemPosition,
  handleToolBoxType,
  handleNavbarColor,
  handleContentWidth,
  handleRouterTransition,
  handleZoom,
  setTool,
  setIsMobileToolActive,
  collapseBottomTool,
  setLineColor,
  setThickness,
  setPaperType
} = smartBookLayoutSlice.actions;

export default smartBookLayoutSlice.reducer;
