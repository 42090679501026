
// ** Third Party Components
import classnames from 'classnames';
import { useState } from 'react';
import { FaTimes, FaWikipediaW } from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Card, CardBody, Input } from 'reactstrap';
import {  Image, Video } from 'react-feather';
import axios from 'axios';
import Window from '../../../../components/Window';
import PixabayResult from './PixeBayResulsts';
import PixabayVideoResult from './PixeBayVideoResults';

// ** Styles

const SearchPage = (props) => {
  // ** Props
  const { isOpen, openSearchPage } =
    props;
  const [searchKey, setSearchKey] = useState('');
  const [pixabaySearchResults, setPixabaySearchResults] = useState();
  const [videoSearchResults, setVideoSearchResults] = useState();
  const [vikipediaLink, setVikipediaLink] = useState();
  const [searchType, setSearchType] = useState('');
  const handleToggle = () => {
    openSearchPage(!isOpen);
  };

  const searchPixebayImage = (page) => {
    axios
      .get(
        `https://pixabay.com/api/?key=32626171-a099492cb2968cbe2664965ce&q=${searchKey}&lang=tr&per_page=10&safesearch=true&image_type=all&page=${page}`
      )
      .then((result) => {
        setPixabaySearchResults(result);
        setSearchType('pixabay');
      });
  };

  const searchVideo = (page) => {
    axios
      .get(
        `https://pixabay.com/api/videos?key=32626171-a099492cb2968cbe2664965ce&q=${searchKey}&lang=tr&per_page=10&safesearch=true&image_type=all&page=${page}`
      )
      .then((result) => {
        setVideoSearchResults(result);
        setSearchType('video');
      });
  };
  const searchInVikipedia = () => {
    const link = `https://tr.wikipedia.org/wiki/${searchKey.replace(
      /\s/g,
      '_'
    )}`;
    setVikipediaLink(link);
    setSearchType('vikipedia');
  };

  const searchQuestion = () => {

    axios
    .get(
      `https://advdijital.com/api/smartbook/sbpublic/getquestionsbykeyword?keyword=${searchKey}`
    )
    .then((result) => {
    });

  };

  return (
    <>
      <div
        className={classnames('side-contents d-md-block', {
          open: isOpen,
        })}
      >
        <PerfectScrollbar
          className="book-contents-content"
          options={{ wheelPropagation: false }}
        >
          <div className="book-contents-header pt-1 pb-0 mb-2 position-relative">
            <h4 className="mb-0">İçerik Ara</h4>
            <button
              className="book-contents-close"
              onClick={() => handleToggle()}
            >
              <FaTimes />
            </button>
          </div>
          <Input
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          ></Input>
          <div className="mt-2">
            <Button
              onClick={() => searchPixebayImage(1)}
              color="primary"
              size="sm"
            >
              <Image size="14"></Image>
              <span className="ms-25">Resim Ara</span>
            </Button>
            <Button
              size="sm"
              color="danger"
              className="ms-1"
              onClick={() => searchVideo(1)}
            >
              <Video size="14"></Video>
              <span className="ms-25">Video Ara</span>
            </Button>
            <Button
              size="sm"
              className="ms-1"
              color="light"
              onClick={() => searchInVikipedia()}
            >
              <FaWikipediaW size="14"></FaWikipediaW>
              <span className="ms-25">Vikipedia</span>
            </Button>
            {/* <Button
              size="sm"
              className="ms-1"
              color="light"
              onClick={() => searchQuestion()}
            >
              <FaWikipediaW size="14"></FaWikipediaW>
              <span className="ms-25">Soru Ara</span>
            </Button> */}
          </div>

          <Card>
            <CardBody>
              {searchType === 'pixabay' && pixabaySearchResults && (
                <PixabayResult
                  searchResults={pixabaySearchResults}
                ></PixabayResult>
              )}
              {searchType === 'video' && videoSearchResults && (
                <PixabayVideoResult searchResults={videoSearchResults} />
              )}
            </CardBody>
          </Card>
        </PerfectScrollbar>
      </div>
      {searchType === 'vikipedia' && vikipediaLink && (
        <Window
          title={searchKey}
          noMin
          noFull
          width={900}
          height={400}
          noMax
          onClose={() => setSearchType('')}
          onResize={(width, height) => console.log()}
        >
          <iframe title={searchKey} src={vikipediaLink}></iframe>
        </Window>
      )}
    </>
  );
};

export default SearchPage;
