// SQLITE IMPORTS
import {} from "@capacitor-community/sqlite";
// JSON FILE WITH DATA
import { sqlite } from "../App";
export const saveLessons = async (data) => {
    let isConn = await sqlite.isConnection("smartBook");
    let db;
    try {
      if (!isConn.result) {
        db = await sqlite.createConnection("smartBook");
      } else {
        db = await sqlite.retrieveConnection("smartBook");
      }
      await db.open();

      let stmt = "INSERT or IGNORE INTO lesson (id, title, displayOrder) values ";
  
      data.map((c) => {
        stmt += `('${c.id}', '${c.title}', '${c.displayOrder}'),`;
      });
      stmt = stmt.slice(0, -1);
  
      await db.query(stmt);
      await db.close();
      await sqlite.closeConnection("smartBook");
      return stmt;
    } catch (err) {
      return Promise.reject(`Error: ${err}`);
    }
  };