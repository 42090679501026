import axios from "axios";
import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiConfig } from "../configs/apiconfig";
import { get } from "../data/IonicStorage";
const instance = axios.create({
  baseURL: `${apiConfig.apiAdress}`,
});

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.authentication);
  // const [isLoading, setIsLoading] = useState(false);
  // const subscribers = [];

  let isAlreadyFetchingAccessToken = false;

  useEffect(() => {
    const reqError = (error) => {
      // setIsLoading(false);
    };
    const resInterceptor = (response) => {
      // setIsLoading(false);
      return response;
    };

    const errInterceptor = (error) => {
      // setIsLoading(false);
      console.log(error);
      const { config, response } = error;
      const originalRequest = config;
      // ** if (status === 401) {
      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          const access_token= get("access_token")
          const refresh_token= get("refresh_token")

          axios
            .post(apiConfig.apiAdress + apiConfig.refreshEndpoint, {
              accessToken: `${access_token}`,
              refreshToken: `${refresh_token}`,
            })
            .then((r) => {
              isAlreadyFetchingAccessToken = false;
              dispatch();
              // updateTokens({
              //   email: r.data.jsonWebToken.email,
              //   accessToken: r.data.jsonWebToken.accessToken,
              //   refreshToken: r.data.refreshToken,
              // })
            });
        }
        const retryOriginalRequest = new Promise((resolve) => {
          config.headers["Content-Type"] = 'application/json';
          originalRequest.headers.Authorization = `Bearer ${authStore.accessToken}`;
          resolve(axios(originalRequest));
        });
        return retryOriginalRequest;
      }
    };

    instance.interceptors.request.use(async (config) => {
      // ** Get token from localStorage
      var access_token= await get("access_token")
      if (access_token && access_token!== "") {
        // config.headers["Access-Control-Allow-Credentials"] =true;
        // config.headers["Access-Control-Allow-Origin"] = '*';
        // config.headers.Authorization = `Bearer ${access_token.replace(
        //   '"/"',
        //   ""
        // )}`;
      }
      return config;
    }, reqError);

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => instance.interceptors.response.eject(interceptor);
  });
  return <>{children}</>;
};

export default instance;
export { AxiosInterceptor };
