import { Link } from "react-router-dom";
import { Card, CardBody, CardText, Button } from "reactstrap";
import classnames from "classnames";
import { FaBookOpen, FaDownload, FaTrash } from "react-icons/fa";
import AsyncImage from "../../../components/asycnImage";
import { useHistory } from "react-router-dom";
const BookCard = ({
  book,
  downloadBook,
  deleteBook
}) => {
  const history= useHistory();
  return (
    <Card className="book-card" key={book.title}>
      <div className="item-img text-center mx-auto">
        <AsyncImage id={book.id} src={`${book.coverImage}`} width={200} />{" "}
      </div>
      <CardBody>
        <h6 className="item-name">
         {book.title}
        </h6>
        <div>
          <CardText tag="span">
            <span className="book-info">Sınıf: {book.classNames}</span>
          </CardText>
        </div>
        <div>
          <CardText tag="span">
            <span className="book-info">Ders: {book.lessonNames}</span>
          </CardText>
        </div>
      </CardBody>
      <div className="item-options text-center">
        {book.isDownloaded !== 1 && (
          <Button
            className="btn-wishlist"
            color="light"
            onClick={() => downloadBook(book.id)}
          >
            <FaDownload
              className={classnames("me-50", {
                "text-danger": book.isInWishlist,
              })}
              size={14}
            />
            <span>İndir</span>
          </Button>
        )}
        {book.isDownloaded === 1 && (
          <>
            <Button
              color="danger"
              className="btn-cart move-cart"
              onClick={() => deleteBook(book.id)}
            >
              <FaTrash className="me-50" size={14} />
              <span>Sil</span>
            </Button>
            <Button
              color="primary"
              onClick={()=> history.push(`/smartBook/${book.id}`)}
              className="btn-cart move-cart"
            >
                <FaBookOpen className="me-50 text-white" size={14} />
              <span className="text-white">Kitaba Git</span>
            </Button>
          </>
        )}
      </div>
    </Card>
  );
};

export default BookCard;
