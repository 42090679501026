import React, { PureComponent } from "react";
import * as ReactDOM from "react-dom";
import PropTypes from "prop-types";
import History from "./../history";
import { uuid4 } from "./../utils";
import Arrow from "./../tools/Arrow";
import Tool from "./../tools";
import DefaultTool from "./../tools/defaul-tool";
import Select from "./../tools/Select";
import Pencil from "./../tools/Pencil";
import Marker from "./../tools/Marker";
import Eraser from "./../tools/Eraser";
import Line from "./../tools/Line";
import Rectangle from "./../tools/Rectangle";
import RectangleLabel from "./../tools/Rectangle/rectangle-label";
import Triangle from "./../tools/Triangle";
import Polygon from "./../tools/Polygon";
import Circle from "./../tools/Circle";
import Pan from "./../tools/Pan";
import Highlighter from "./../tools/Highlighter";
import { fabric } from "fabric";
import { getOptionImage } from "../../data/IonicStorage";
/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {
  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColor: PropTypes.string,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    //zoomFactor value maintain
    zoomFactor: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.string,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // event object added
    onObjectAdded: PropTypes.func,
    // event object modified
    onObjectModified: PropTypes.func,
    // event object removed
    onObjectRemoved: PropTypes.func,
    // event mouse down
    onMouseDown: PropTypes.func,
    // event mouse move
    onMouseMove: PropTypes.func,
    // event mouse up
    onMouseUp: PropTypes.func,
    // event mouse out
    onMouseOut: PropTypes.func,
    // event object move
    onObjectMoving: PropTypes.func,
    // event object scale
    onObjectScaling: PropTypes.func,
    // event object rotating
    onObjectRotating: PropTypes.func,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object,
    // Style options to pass to container div of canvas
    onImagePositionChange: PropTypes.func,
    //set tool
    onSetTool: PropTypes.func
  };

  static defaultProps = {
    lineColor: "black",
    lineWidth: 10,
    fillColor: "transparent",
    backgroundColor: "transparent",
    opacity: 1.0,
    undoSteps: 25,
    zoomFactor: 1.0,
    tool: null,
    widthCorrection: 0,
    heightCorrection: 0,
    forceValue: false,
    onObjectAdded: () => null,
    onObjectModified: () => null,
    onObjectRemoved: () => null,
    onMouseDown: () => null,
    onMouseMove: () => null,
    onMouseUp: () => null,
    onMouseOut: () => null,
    onObjectMoving: () => null,
    onObjectScaling: () => null,
    onObjectRotating: () => null,
    onSetTool: () => null
  };

  state = {
    action: true,
    zoomFactor: this.props.zoomFactor
  };
  _initTools = (fabricCanvas) => {
    this._tools = {};
    this._tools[Tool.Select] = new Select(fabricCanvas);
    this._tools[Tool.Pencil] = new Pencil(fabricCanvas);
    this._tools[Tool.Marker] = new Marker(fabricCanvas);
    this._tools[Tool.Eraser] = new Eraser(fabricCanvas);
    this._tools[Tool.Line] = new Line(fabricCanvas);
    this._tools[Tool.Arrow] = new Arrow(fabricCanvas);
    this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas);
    this._tools[Tool.RectangleLabel] = new RectangleLabel(fabricCanvas);
    this._tools[Tool.Triangle] = new Triangle(fabricCanvas);
    this._tools[Tool.Polygon] = new Polygon(fabricCanvas);
    this._tools[Tool.Circle] = new Circle(fabricCanvas);
    this._tools[Tool.Pan] = new Pan(fabricCanvas);
    this._tools[Tool.Highlighter] = new Highlighter(fabricCanvas);
    this._tools[Tool.DefaultTool] = new DefaultTool(fabricCanvas);
  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */
  addImg = (dataUrl, options = {}) => {
    let canvas = this._fc;
    fabric.Image.fromURL(dataUrl, (oImg) => {
      let opts = {
        left: Math.random() * (canvas.getWidth() - oImg.width * 0.5),
        top: Math.random() * (canvas.getHeight() - oImg.height * 0.5),
        scale: 0.5,
      };
      Object.assign(opts, options);
      oImg.scale(opts.scale);
      oImg.set({
        left: opts.left,
        top: opts.top,
      });
      canvas.add(oImg);
    });
  };

  /**
   * Action when an object is added to the canvas
   */
  _onObjectAdded = (e) => {
    const { onObjectAdded } = this.props;
    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }
    let obj = e.target;
    obj.__version = 1;
    // record current object state as json and save as originalState
    let objState = obj.toJSON();
    obj.__originalState = objState;
    let state = JSON.stringify(objState);
    // object, previous state, current state
    this._history.keep([obj, state, state]);
    onObjectAdded(e);
  };

  /**
   * Action when an object is moving around inside the canvas
   */
  _onObjectMoving = (e) => {
    const { onObjectMoving } = this.props;
    onObjectMoving(e);
  };

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = (e) => {
    const { onObjectScaling } = this.props;
    onObjectScaling(e);
  };

  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = (e) => {
    const { onObjectRotating } = this.props;
    onObjectRotating(e);
  };

  _onObjectModified = (e) => {
    const { onObjectModified } = this.props;
    let obj = e.target;
    obj.__version += 1;
    let prevState = JSON.stringify(obj.__originalState);
    let objState = obj.toJSON();
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    let currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);
    onObjectModified(e);
  };

  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = (e) => {
    const { onObjectRemoved } = this.props;
    let obj = e.target;
    if (obj.__removed) {
      obj.__version += 1;
      return;
    }
    obj.__version = 0;
    onObjectRemoved(e);
  };

  /**
   * Action when the mouse button is pressed down
   */
  _onMouseDown = (e) => {
    const { onMouseDown } = this.props;
    if (e.target && e.target.id && e.target.id.includes("gapFillingItem")) {
      return;
    }
    this._selectedTool.doMouseDown(e);
    onMouseDown(e);
  };
  setPan = (e) => {
    if (e) {
      this._selectedTool.doMouseUp(e);
      this.setTool(Tool.Pan)
      if (this._selectedTool) {
        this._selectedTool.doMouseDown(e);
      }
    }
  }
  setEraser = (e) => {
    if (e) {
      this._selectedTool.doMouseUp(e);
      this.setTool(Tool.Eraser)
      this._selectedTool.doMouseDown(e);
    }
  }
  setTool = (tool) => {
    let selectedTool = this._tools[tool];
    if (selectedTool) selectedTool.configureCanvas(this.props);
    this._selectedTool = selectedTool;
  }
  onSetTool = (tool) => {
    const { onSetTool } = this.props;
    onSetTool(tool)
  }
  /**
   * Action when the mouse cursor is moving around within the canvas
   */
  _onMouseMove = (e) => {
    const { onMouseMove } = this.props;
    this._selectedTool.doMouseMove(e, this.getImageCoordinate);

    onMouseMove(e);
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  _onMouseOut = (e) => {
    const { onMouseOut } = this.props;
    this._selectedTool.doMouseOut(e);
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
    onMouseOut(e);
  };

  _onMouseUp = (e) => {
    const { onMouseUp } = this.props;
    this._selectedTool.doMouseUp(e);
    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool !== Tool.Pencil) {
      const canvas = this._fc;
      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON();
      }
    }
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
    onMouseUp(e);
  };

  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */
  _resize = (e, canvasWidth = null, canvasHeight = null) => {
    if (e) e.preventDefault();
    let { widthCorrection, heightCorrection } = this.props;
    let canvas = this._fc;
    let { offsetWidth, clientHeight } = this._container;
    let prevWidth = canvasWidth || canvas.getWidth();
    let prevHeight = canvasHeight || canvas.getHeight();
    let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
    let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
    canvas.setWidth(offsetWidth - widthCorrection);
    canvas.setHeight(clientHeight - heightCorrection);
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      let bi = canvas.backgroundImage;
      bi.width = bi.width * wfactor;
      bi.height = bi.height * hfactor;
    }
    let objects = canvas.getObjects();
    for (let i in objects) {
      let obj = objects[i];
      let scaleX = obj.scaleX;
      let scaleY = obj.scaleY;
      let left = obj.left;
      let top = obj.top;
      let tempScaleX = scaleX * wfactor;
      let tempScaleY = scaleY * hfactor;
      let tempLeft = left * wfactor;
      let tempTop = top * hfactor;
      obj.scaleX = tempScaleX;
      obj.scaleY = tempScaleY;
      obj.left = tempLeft;
      obj.top = tempTop;
      obj.setCoords();
    }
    canvas.renderAll();
    canvas.calcOffset();
  };

  /**
   * Sets the background color for this sketch
   * @param color in rgba or hex format
   */
  _backgroundColor = (color) => {
    if (!color) return;
    let canvas = this._fc;
    canvas.setBackgroundColor(color, () => canvas.renderAll());
  };
  /**
   * On mouseup after drawing the path on contextTop canvas
   * we use the points captured to create an new fabric path object
   * and add it to the fabric canvas.
   */
  _finalizeAndAddPath = () => {
    var ctx = this._fc;
    ctx.restore();
    ctx.closePath();

    var pathData = this.convertPointsToSVGPath(this._points).join("");
    if (pathData === "M 0 0 Q 0 0 0 0 L 0 0") {
      // do not create 0 width/height paths, as they are
      // rendered inconsistently across browsers
      // Firefox 4, for example, renders a dot,
      // whereas Chrome 10 renders nothing
      this.canvas.renderAll();
      return;
    }

    var path = this.createPath(pathData);

    this.canvas.add(path);
    path.setCoords();

    this.canvas.clearContext(this.canvas.contextTop);
    this._resetShadow();
    this.canvas.renderAll();

    // fire event 'path' created
    this.canvas.fire("path:created", { path: path });
  };
  getImageCoordinate = () => {
    let canvas = this._fc;
    if (canvas.backgroundImage) {
      const { onImagePositionChange, width, height } = this.props;
      const left = canvas.backgroundImage.lineCoords.bl.x;
      const top = canvas.backgroundImage.lineCoords.tl.y;
      const imgWidth =
        canvas.backgroundImage.lineCoords.br.x -
        canvas.backgroundImage.lineCoords.bl.x;
      const imgHeight =
        canvas.backgroundImage.lineCoords.br.y -
        canvas.backgroundImage.lineCoords.tr.y;
      let scale = this.state.zoomFactor;
      onImagePositionChange({
        left: left,
        top: top,
        width: imgWidth,
        height: imgHeight,
        sketchWidth: width,
        sketchHeight: height,
        scale: scale,
      });
    }
  };
  /**
   * Zoom the drawing by the factor specified
   *
   * The zoom factor is a percentage with regards the original, for example if factor is set to 2
   * it will double the size whereas if it is set to 0.5 it will half the size
   *
   * @param factor the zoom factor
   */
  pinchzoom = (factor, pointX, pointY) => {
    let canvas = this._fc;

    if (factor > 0.5 && factor < 10) {
      canvas.zoomToPoint(new fabric.Point(pointX, pointY), factor);
      this.setState(() => ({
        zoomFactor: factor,
      }));
      canvas.renderAll();
      canvas.calcOffset();
    }
  };

  zoom = (factor, pointX, pointY) => {
    let canvas = this._fc;
    if (factor > 0.5) {
      canvas.zoomToPoint(
        new fabric.Point(pointX, pointY),
        factor
      );
      this.setState((state) => ({
        zoomFactor: factor,
      }));
      canvas.renderAll();
      canvas.calcOffset();
    }
  };
  zoomIn = () => {
    let canvas = this._fc;
    var center = canvas.getCenter();
    var factor = 0.2;
    const zoomRatio = this.state.zoomFactor + factor;
    if (zoomRatio > 0.5) {
      canvas.zoomToPoint(new fabric.Point(center.top, center.left), zoomRatio);
      this.setState((state) => ({
        zoomFactor: zoomRatio,
      }));
      canvas.renderAll();
      canvas.calcOffset();
    }
  };
  zoomOut = () => {
    let canvas = this._fc;
    var center = canvas.getCenter();
    var factor = 0.2;
    const zoomRatio = this.state.zoomFactor - factor;
    if (zoomRatio > 0.5) {
      canvas.zoomToPoint(new fabric.Point(center.top, center.left), zoomRatio);
      this.setState((state) => ({
        zoomFactor: zoomRatio,
      }));
      canvas.renderAll();
      canvas.calcOffset();
    }
  };
  wheelZoom = ({ zoom, point }) => {
    let canvas = this._fc;
    canvas.zoomToPoint(point, zoom);
    // canvas.setHeight(canvas.height / this.state.zoomFactor)* zoom;
    this.setState((state) => ({ zoomFactor: zoom }));
    canvas.renderAll();
    canvas.calcOffset();
    this.getImageCoordinate();
  };

  fitCanvas = () => {
    let canvas = this._fc;
    canvas.renderAll();
  };

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    let history = this._history;
    let [obj, prevState, currState] = history.getCurrent();
    history.undo();
    if (obj.__removed) {
      this.setState({ action: false }, () => {
        this._fc.add(obj);
        obj.__version -= 1;
        obj.__removed = false;
      });
    } else if (obj.__version <= 1) {
      this._fc.remove(obj);
    } else {
      obj.__version -= 1;
      obj.setOptions(JSON.parse(prevState));
      obj.setCoords();
      this._fc.renderAll();
    }
    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    let history = this._history;
    if (history.canRedo()) {
      let canvas = this._fc;
      //noinspection Eslint
      let [obj, prevState, currState] = history.redo();
      if (obj.__version === 0) {
        this.setState({ action: false }, () => {
          canvas.add(obj);
          obj.__version = 1;
        });
      } else {
        obj.__version += 1;
        obj.setOptions(JSON.parse(currState));
      }
      obj.setCoords();
      canvas.renderAll();
      if (this.props.onChange) {
        this.props.onChange();
      }
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo();
  };

  /**
   * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
   *
   * Available Options are
   * <table style="width:100%">
   *
   * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
   * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
   * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
   * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
   * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
   * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
   * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
   * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
   *
   * </table>
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = (options) => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  fromJSON = (json, dataUrl, options = {}) => {
    if (!json) return;
    let canvas = this._fc;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        if (this.props.tool === Tool.DefaultTool) {
          canvas.isDrawingMode = canvas.selection = false;
          canvas.forEachObject((o) => (o.selectable = o.evented = false));
        }
        if (dataUrl) {
          this.setBackgroundFromDataUrl(dataUrl, options);
        }
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }, 100);
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */
  clear = (propertiesToInclude) => {
    let canvas = this._fc;

    let objects = canvas.getObjects();
    if (objects) {
      let selected = [];
      objects.forEach((obj) => selected.push(obj));
      selected.forEach((obj) => {
        if (
          obj.id == undefined ||
          (!obj.id.includes("gapFilling") &&
            !obj.id.includes("pageItem") &&
            !obj.id.includes("mediaItem") &&
            !obj.id.includes("solution"))
        ) {
          obj.__removed = true;
          let objState = obj.toJSON();
          obj.__originalState = objState;
          let state = JSON.stringify(objState);
          canvas.remove(obj);
        }
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
    this._history.clear();

    let discarded = this.toJSON(propertiesToInclude);
    return discarded;
  };

  hasSelection = () => {
    let canvas = this._fc;
    return !!canvas.getActiveObject();
  };

  editTextWithVirtualKeyboard = (value) => {
    let canvas = this._fc;
    const text = canvas.getActiveObject();
    text.set({ text: value });
    text.enterEditing();
    canvas.renderAll();
  };

  clearSelection = () => {
    let canvas = this._fc;
    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

  /**
   * Remove selected object from the canvas
   */
  removeSelected = () => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();
    if (activeObj) {
      let selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject((obj) => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      selected.forEach((obj) => {
        obj.__removed = true;
        let objState = obj.toJSON();
        obj.__originalState = objState;
        let state = JSON.stringify(objState);
        this._history.keep([obj, state, state]);
        canvas.remove(obj);
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  };

  copy = () => {
    let canvas = this._fc;
    canvas.getActiveObject().clone((cloned) => (this._clipboard = cloned));
  };

  paste = () => {
    // clone again, so you can do multiple copies.
    this._clipboard.clone((clonedObj) => {
      let canvas = this._fc;
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj.type === "activeSelection") {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject((obj) => canvas.add(obj));
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      this._clipboard.top += 10;
      this._clipboard.left += 10;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();
    });
  };

  /**
   * Sets the background from the dataUrl given
   *
   * @param dataUrl the dataUrl to be used as a background
   * @param options
   */

  clearCanvasBackgroud = () => {
    let canvas = this._fc;
    canvas.setBackgroundImage(null, () => canvas.renderAll(), null);
  };

  clearItems = () => {
    let canvas = this._fc;
    let objects = canvas.getObjects();
    if (objects) {
      let selected = [];
      objects.forEach((obj) => selected.push(obj));
      selected.forEach((obj) => {
        if (
          obj.id == undefined ||
          obj.id.includes("gapFilling") ||
          obj.id.includes("pageItem") ||
          obj.id.includes("mediaItem") ||
          obj.id.includes("gapFillingItem") ||
          obj.id.includes("solution")
        ) {
          obj.__removed = true;
          let objState = obj.toJSON();
          obj.__originalState = objState;
          canvas.remove(obj);
        }
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
    this._history.clear();
  };

  showAllGapFillingAnswer = (openAnswers) => {
    let canvas = this._fc;
    let objects = canvas.getObjects();
    objects.forEach((obj) => {
      if (openAnswers) {
        if (obj.id && obj.id.includes("gapFillingCloseIcon-")) {
          obj.fire("mousedown");
        }
      } else if (obj.id && obj.id.includes("gapFillingItemIcon-")) {
        obj.fire("mousedown");
      }
    });
    setTimeout(() => {
      let objects2 = canvas.getObjects();
      objects2.forEach((obj) => {
        if (openAnswers) {
          if (obj.id && obj.id.includes("gapFillingItemIcon-")) {
            obj.fire("mousedown");
          }
        } else if (obj.id && obj.id.includes("gapFillingCloseIcon-")) {
          obj.fire("mousedown");
        }
      });
    }, 10);
  };

  closeGapFillingAnswer = (
    questionId,
    gapFillingItem,
    left,
    top,
    ratio,
    iconScale
  ) => {
    let canvas = this._fc;
    let button = canvas
      .getObjects()
      .find(
        (x) =>
          x.hasOwnProperty("id") &&
          x.id == `gapFillingCloseIcon-${gapFillingItem.id}`
      );
    canvas.remove(button);
    let answer = canvas
      .getObjects()
      .find(
        (x) =>
          x.hasOwnProperty("id") &&
          x.id == `gapFillingAnswer-${gapFillingItem.id}`
      );
    canvas.remove(answer);
    this.laodIcon(
      "eye.svg",
      {
        id: `gapFillingItemIcon-${gapFillingItem.id}`,
        left: left - 50 * ratio,
        top: top,
        iconWidth: 50,
      },
      iconScale,
      () =>
        this.showGapFillingAnswer(
          questionId,
          gapFillingItem,
          left,
          top,
          ratio,
          iconScale
        )
    );
  };

  showGapFillingAnswer = async (
    questionId,
    gapFillingItem,
    left,
    top,
    ratio,
    iconScale
  ) => {
    let canvas = this._fc;
    let object = canvas
      .getObjects()
      .find(
        (x) =>
          x.hasOwnProperty("id") &&
          x.id == `gapFillingItemIcon-${gapFillingItem.id}`
      );
    canvas.remove(object);
    canvas.renderAll();
    this.laodIcon(
      "eye-close.svg",
      {
        id: `gapFillingCloseIcon-${gapFillingItem.id}`,
        left: left - 50 * ratio,
        top: top,
        iconWidth: 50,
      },
      iconScale,
      () =>
        this.closeGapFillingAnswer(
          questionId,
          gapFillingItem,
          left,
          top,
          ratio,
          iconScale
        )
    );
    const pageImage = await getOptionImage(questionId, gapFillingItem.id);
    if (pageImage) {
      fabric.Image.fromURL(`data:image/png;base64,${pageImage}`, (img) => {
        img.set({
          left: left,
          top: top,
          id: `gapFillingAnswer-${gapFillingItem.id}`,
          erasable: false,
          selectable: false,
          evented: false,
        });
        img.scale(ratio);
        canvas.add(img);
      });
    } else if (gapFillingItem.correctAnswer) {
      let iText = new fabric.IText(gapFillingItem.correctAnswer, {
        left: left,
        top: top,
        erasable: false,
        selectable: false,
        backgroundColor: "#fff",
        fontSize: 100 * ratio,
        evented: false,
        id: `gapFillingAnswer-${gapFillingItem.id}`,
      });
      canvas.add(iText);
      canvas.moveTo(iText, -1);
    }
  };

  getMediaItemPosition = (item, imgLeft, imgTop, ratio) => {
    let left = item.axisX;
    let top = item.axisY;
    if (item.isBookActivity === 1) {
      left = imgLeft + (left - 50) * ratio;
      top = imgTop + (top - 20) * ratio;
    }
    return { left, top };
  };

  laodIcon = (icon, options, ratio, onClick) => {
    let canvas = this._fc;
    // eslint-disable-next-line @typescript-eslint/no-shadow

    fabric.Image.fromURL(`/assets/smartbookicons/${icon}`, (img) => {
      img.set(options);
      img.set({
        erasable: false,
        selectable: false,
      });
      img.scale(ratio);
      img.on("mousedown", function () {
        onClick();
      });
      canvas.add(img);
      canvas.renderAll();
    });
  };

  loadPageItems = (
    pageItems,
    userItems,
    imgLeft,
    imageTop,
    ratio,
    height,
    onClick
  ) => {
    userItems.forEach((item, index) => {
      var pos = this.getMediaItemPosition(item, imgLeft, imageTop, ratio);
      this.laodIcon(
        "img.svg",
        {
          id: `mediaItem-${item.id}`,
          left: pos.left,
          top: pos.top,
          iconWidth: 100,
        },
        1,
        () => onClick(index, item, "mediaItem")
      );
    });
    pageItems.forEach((item, index) => {
      var leftPos =
        imgLeft + ((item.axisX - 50) * ratio * height) / item.imageHeight;
      var topPos =
        imageTop + ((item.axisY - 20) * ratio * height) / item.imageHeight;
      this.laodIcon(
        "zoom.svg",
        {
          id: `pageItem-${item.id}`,
          left: leftPos,
          top: topPos,
          iconWidth: 100,
        },
        1,
        () => onClick(index, item)
      );
      if (item.pageItemType === 20) {
        item.options.forEach((element) => {
          var left = imgLeft + element.axisX * ratio;
          var top = imageTop + element.axisY * ratio;
          this.laodIcon(
            "eye.svg",
            {
              id: `gapFillingItemIcon-${element.id}`,
              left: left - 50 * ratio,
              top: top,
              iconWidth: 50,
            },
            1,
            () =>
              this.showGapFillingAnswer(item.id, element, left, top, ratio, 1)
          );
        });
      }
    });
  };

  setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    let canvas = this._fc;
    const {
      isBottomToolOpen,
      isFullWidth,
      pageItems,
      userItems,
      onQuestionClick,
    } = options;
    var center = canvas.getCenter();
    var ratio = 1;
    var clearItems = this.clearItems;
    var loadPageItems = this.loadPageItems;
    var handleZoom = this.props.handleZoom;
    var zoomState = this.props.zoomState;
    fabric.Image.fromURL(`data:image/png;base64,${dataUrl}`, function (img) {
      img.set({ erasable: false });
      clearItems();
      if (isFullWidth) {
        ratio = canvas.width / img.width;
        // canvas.setHeight(img.height * ratio);
      } else {
        const ratioW = canvas.width / img.width;
        ratio = (canvas.height - (isBottomToolOpen ? 70 : 0)) / img.height;
        if (ratioW < ratio) {
          ratio = ratioW
        }
        // canvas.setHeight(img.height * ratio);
      }

      const { stretched, stretchedX, stretchedY, ...fabricOptions } = {
        stretched: false,
        stretchedX: false,
        stretchedY: false,
        top: (img.height * ratio) / 2,
        left: center.left,
        originX: "center",
        originY: "center",
        scaleX: ratio,
        scaleY: ratio,
      };
      if (stretched || stretchedX) img.scaleToWidth(canvas.width);
      if (stretched || stretchedY) img.scaleToHeight(canvas.height);
      canvas.setBackgroundImage(img, () => canvas.renderAll(), fabricOptions);
      var imgLeft = img.aCoords ? img.aCoords.bl.x : 0;
      var imgTop = img.aCoords ? img.aCoords.tl.y : 0;

      loadPageItems(
        pageItems,
        userItems,
        imgLeft,
        imgTop,
        ratio,
        img.height,
        onQuestionClick
      );
      handleZoom({
        ...zoomState,
        pageScale: ratio,
        scale: ratio,
        zoomRatio: 1,
      });
    });
  };

  cropImage = (dataUrl, options = {}) => {
    this.clearItems();
    let canvas = this._fc;
    canvas.renderAll();
    const {
      sketchHeight,
      sketchWidth,
      pageItem,
      showRootImage,
      rootDataUrl,
      pageItemPosition,
      pageItemIsFull,
      showImageSolution,
      showVideoSolution,
    } = options;
    fabric.Image.fromURL(`data:image/png;base64,${dataUrl}`, (oImg) => {
      var ratioY = oImg.height / pageItem.imageHeight;
      var ratioX = oImg.width / pageItem.imageWidth;
      var maxHeight = sketchHeight;
      var maxWidth = sketchWidth;
      if(maxHeight>600){
        maxHeight=maxHeight-200;
      }
      if(maxWidth>1000){
        maxWidth=maxWidth-300;
      }
      var scale = 1;
      var maxScale = 3.5;
      var scaleY = maxHeight / (pageItem.height * ratioY);
      if (showRootImage && pageItem.rootHeight > pageItem.height) {
        scaleY = pageItem.rootHeight * ratioY;
      }
      var scaleX =
        maxWidth /
        (pageItem.width * ratioX +
          (showRootImage ? pageItem.rootWidth : 0) * ratioX);
       scale = scaleY;
      if (scaleX < scaleY) {
        scale = scaleX;
      }
      if (!pageItemIsFull) {
        scale = scale / 2;
      }

      if (scale / this.props.zoomState.pageScale > maxScale) {
        scale = this.props.zoomState.pageScale * maxScale;
      }
      this.pinchzoom(1, 0, 0);
      let left = 0;
      if (pageItemPosition === "left") {
        if(maxWidth>1000){
          left = 140;
        }
      } else if (pageItemPosition === "right") {
        left = canvas.width - pageItem.width * ratioX * scale;
      } else if (pageItemPosition === "center") {
        left = canvas.width / 2 - (pageItem.width * ratioX * scale) / 2;
      }
      // if (pageItem.solutionImage) {
      //   this.laodIcon(
      //     "img.svg",
      //     {
      //       id: `solution-image-${pageItem.id}`,
      //       left: left - 100,
      //       top: 0,
      //       iconWidth: 80 * scale,
      //     },
      //     scale / this.props.zoomState.pageScale,
      //     () => showImageSolution()
      //   );
      // }
      // if (pageItem.solutionVideo) {
      //   this.laodIcon(
      //     "video.svg",
      //     {
      //       id: `solution-video-${pageItem.id}`,
      //       left: left - 100,
      //       top: pageItem.solutionImage ? 120 : 0,
      //       iconWidth: 100 * scale,
      //     },
      //     scale / this.props.zoomState.pageScale,
      //     () => showVideoSolution()
      //   );
      // }
      let opts = {
        scale: scale,
        top: 0,
        left: left,
        scaleX: scale,
        scaleY: scale,
        cropX: pageItem.axisX * ratioX,
        cropY: pageItem.axisY * ratioY,
        width: pageItem.width * ratioX,
        height: pageItem.height * ratioY,
        erasable: false,
      };
      if (false) {
        fabric.Image.fromURL(
          `data:image/png;base64,${rootDataUrl ? rootDataUrl : dataUrl}`,
          (rootImage) => {
            if (pageItemPosition === "left") {
              left = 40;
            } else if (pageItemPosition == "right") {
              left =
                canvas.width -
                (pageItem.width + pageItem.rootWidth) * ratioX * scale;
            } else if (pageItemPosition == "center") {
              left =
                canvas.width / 2 -
                ((pageItem.width + pageItem.rootWidth) * ratioX * scale) / 2;
            }
            // var ratioRootY = rootImage.height / pageItem.rootImageHeight;
            // var ratioRoot = rootImage.width / pageItem.rootImageWidth;
            let rootOptions = {
              scale: scale,
              top: 0,
              left: left,
              scaleX: scale,
              scaleY: scale,
              cropX: pageItem.rootAxisX * ratioX,
              cropY: pageItem.rootAxisY * ratioY,
              width: pageItem.rootWidth * ratioX,
              height: pageItem.rootHeight * ratioY,
              erasable: false,
            };
            oImg.set({ ...opts, left: left + pageItem.rootWidth * scale });
            rootImage.set(rootOptions);
            var group = new fabric.Group([oImg, rootImage]);
            canvas.setBackgroundImage(group, () => canvas.renderAll());
          }
        );
      } else {
        oImg.set(opts);
        canvas.setBackgroundImage(oImg, () => canvas.renderAll());
      }

      if (pageItem.pageItemType === 20) {
        pageItem.options.forEach((element) => {
          var imgLeft = oImg.aCoords
            ? oImg.aCoords.bl.x + (element.axisX - pageItem.axisX) * scale
            : left + element.axisX * scale;
          var top = (element.axisY - pageItem.axisY) * scale;
          this.laodIcon(
            "eye.svg",
            {
              id: `gapFillingItemIcon-${element.id}`,
              left: imgLeft - 50 * scale,
              top: top,
              iconWidth: 50,
            },
            scale / this.props.zoomState.pageScale,
            () =>
              this.showGapFillingAnswer(
                pageItem.id,
                element,
                imgLeft,
                top,
                scale,
                scale / this.props.zoomState.pageScale
              )
          );
        });
      }
      this.props.handleZoom({ ...this.props.zoomState, scale: scale });
    });
  };

  addText = (text, options = {}) => {
    let canvas = this._fc;
    let iText = new fabric.IText(text, {
      left: options.pointer.x,
      top: options.pointer.y,
    });

    canvas.add(iText).setActiveObject(iText);
    iText.enterEditing();
  };

  callEvent = (e, eventFunction) => {
    if (this._selectedTool) eventFunction(e);
  };

  componentDidMount = () => {
    const element = document.getElementsByClassName("canvas-container");

    if (element.length <= 0) {
      let {
        tool,
        value,
        undoSteps,
        zoomFactor,
        defaultValue,
        backgroundColor,
      } = this.props;

      let canvas = (this._fc = new fabric.Canvas(this._canvas, {
        controlsAboveOverlay: true,
      }));

      this._initTools(canvas);

      // set initial backgroundColor
      this._backgroundColor(backgroundColor);
      this.setTool(tool)
      // let selectedTool = this._tools[tool];
      // if (selectedTool) selectedTool.configureCanvas(this.props);
      // this._selectedTool = selectedTool;

      // Control resize
      window.addEventListener("resize", this._resize, false);

      // Initialize History, with maximum number of undo steps
      this._history = new History(undoSteps);
      // Events binding
      canvas.on("object:added", (e) => this.callEvent(e, this._onObjectAdded));
      canvas.on("object:modified", (e) =>
        this.callEvent(e, this._onObjectModified)
      );
      canvas.on("object:removed", (e) =>
        this.callEvent(e, this._onObjectRemoved)
      );

      canvas.on("mouse:down", (e) => this.callEvent(e, this._onMouseDown));
      canvas.on("mouse:move", (e) => this.callEvent(e, this._onMouseMove));

      let pausePanning, pinching = false;
      var prevTool = Tool.Pencil;
      let currentX, currentY, lastX, lastY, xChange, yChange, zoomStartScale = 0
      canvas.on("touch:gesture", (e) => {
        if (e.e.touches && e.e.touches.length === 2) {
          pausePanning = true;
          var point = new fabric.Point(e.self.x, e.self.y);
          if (e.self.state === "start") {

            pinching = true;
            prevTool = this._selectedTool.constructor.name.toLowerCase();
            this.setPan(e)
            this.onSetTool(Tool.Pan)
            zoomStartScale = canvas.getZoom();
          }
          var delta = zoomStartScale * e.self.scale;
          if (delta > 0.5 && delta < 10) {
            canvas.zoomToPoint(point, delta);
          }
          pausePanning = false;
        }
      },
        'object:selected', () => {
          pausePanning = true;
        },
        'selection:cleared', () => {
          pausePanning = false;
        },
        'touch:drag', (e) => {
          if (pausePanning === false && undefined !== e.self.x && undefined !== e.self.x) {
            currentX = e.self.x;
            currentY = e.self.y;
            xChange = currentX - lastX;
            yChange = currentY - lastY;

            if ((Math.abs(currentX - lastX) <= 50) && (Math.abs(currentY - lastY) <= 50)) {
              var delta = new fabric.Point(xChange, yChange);
              canvas.relativePan(delta);
            }
            lastX = e.self.x;
            lastY = e.self.y;
          }
        }
      )
      canvas.on('touch:longpress', (e) => {
        if(pinching)
        return;
        if (this._fc.isDrawingMode) {
          if (e.e.type === 'touchstart') {
            prevTool = this._selectedTool.constructor.name.toLowerCase();
            // this.setTool(Tool.Eraser)
            if(prevTool===Tool.Eraser){
              this.onSetTool(Tool.Pencil)
            }else{
              this.onSetTool(Tool.Eraser)
            }    
          }
          if (e.e.type === 'touchend' && prevTool!==Tool.Eraser) {
            this.onSetTool(prevTool)   
          }
          // const { onLongPress } = this.props;
          // this.callEvent(e, this._onMouseUp);
          // onLongPress(e)
        }
      })
      canvas.on('touch:swiped', (e) => {
        console.log("swiped")
      })
      canvas.on("mouse:up", (e) => {
        if (pinching) {
          this.onSetTool(prevTool)
          pinching = false
        }
        this.callEvent(e, this._onMouseUp);
        let objects = canvas.getObjects().filter((x) => x.hasOwnProperty("id"));
        if (objects) {
          objects.forEach((obj) => {
            if (
              obj.id.includes("gapFillingItemIcon") ||
              obj.id.includes("gapFillingCloseIcon") ||
              obj.id.includes("pageItem") ||
              obj.id.includes("mediaItem") ||
              obj.id.includes("solution-video") ||
              obj.id.includes("solution-image") ||
              obj.id.includes("gapFillingItem")
            ) {
              canvas.bringToFront(obj);
            }
          });
        }
      });
      canvas.on("mouse:out", (e) => this.callEvent(e, this._onMouseOut));
      canvas.on("object:moving", (e) =>
        this.callEvent(e, this._onObjectMoving)
      );
      canvas.on("object:scaling", (e) =>
        this.callEvent(e, this._onObjectScaling)
      );
      canvas.on("object:rotating", (e) =>
        this.callEvent(e, this._onObjectRotating)
      );

      // IText Events fired on Adding Text
      // canvas.on("text:event:changed", console.log)
      // canvas.on("text:selection:changed", console.log)
      // canvas.on("text:editing:entered", console.log)
      // canvas.on("text:editing:exited", console.log)
      // canvas.on("mouse:wheel", function(opt) {
      //   zoom()
      //   this.callEvent(opt, this.zoom)
      // })
      // canvas.on("mouse:wheel", function(opt) {
      //   opt.e.preventDefault()
      //   opt.e.stopPropagation()
      //   if (opt.e.ctrlKey) {
      //     console.log("pinch")
      //     var delta = opt.e.deltaY;
      //     var zoom = canvas.getZoom();
      //     zoom *= 0.999 ** delta;
      //     canvas.setZoom(zoom);
      //   } else {
      //     var e = opt.e;
      //     var vpt = this.viewportTransform;
      //     vpt[4] += e.deltaX;
      //     vpt[5] += e.deltaY;
      //     this.requestRenderAll();
      //   }
      // })
      this.disableTouchScroll();

      this._resize();

      // initialize canvas with controlled value if exists
      (value || defaultValue) && this.fromJSON(value || defaultValue);
    }
  };

  componentWillUnmount = () =>
    window.removeEventListener("resize", this._resize);

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      this._resize();
    }
    if (
      this.props.tool !== prevProps.tool ||
      this.props.lineWidth !== prevProps.lineWidth ||
      this.props.lineColor !== prevProps.lineColor
    ) {
      this._selectedTool = this._tools[this.props.tool];
      //Bring the cursor back to default if it is changed by a tool
      if (this._selectedTool) {
        this._selectedTool.configureCanvas(this.props);
        this._fc.defaultCursor = "move";
      }
    }

    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this._backgroundColor(this.props.backgroundColor);
    }

    if (
      this.props.value !== prevProps.value ||
      (this.props.value && this.props.forceValue)
    ) {
      this.fromJSON(this.props.value);
    }
  };

  render = () => {
    let { className, style, width, height } = this.props;

    let canvasDivStyle = Object.assign(
      {},
      style ? style : {},
      width ? { width: width } : { width: "600px" },
      height ? { height: height } : { height: "600px" }
    );
    return (
      <div
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        className={className}
        ref={(c) => (this._container = c)}
        style={canvasDivStyle}
      >
        <canvas id={uuid4()} ref={(c) => (this._canvas = c)}>
          Sorry, Canvas HTML5 element is not supported by your browser :(
        </canvas>
      </div>
    );
  };
}
const mapStateToProps = (state) => ({
  counter: state.counter,
  canvasHeight: state.canvasHeight,
});
export default React.memo(SketchField);
