import InputNumber from 'rc-input-number';
import { Plus, Minus } from 'react-feather';

const ThicknessInput = ({ value, updateThicknesses, index }) => {
  return <InputNumber
    defaultValue={value}
    upHandler={<Plus />}
    downHandler={<Minus />}
    className="me-1 input-primary"
    onChange={(v) => updateThicknesses(v, index)}
  />;
};

export default ThicknessInput;
