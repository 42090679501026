import {
  FaGripLines,
  FaGripHorizontal,
  FaSave,
  FaTimes,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import { useIonAlert } from "@ionic/react";
import {} from "../../store";
import { saveUserPage, updateUserPage } from "../../../../database/userPageDatabase";

const BlankPageTools = ({
  store,
  bookStore,
  dispatch,
  setDisplayType,
  setPaperType,
  canvas,
}) => {
  const [presentAlert] = useIonAlert();

  const deleteUserPage = () => {
    // window.electron.deleteUserPage(bookStore.userPage.id).then((res) => {
    //   canvas.clear()
    //   Swal.fire('Sayfa Silindi', 'Sayfa başarıyla silindi.', 'success')
    //   dispatch(setUserPage(null))
    // })
  };


  const savePage = () => {
    const json = JSON.stringify(canvas.toJSON());
    const save = (title) => {
      saveUserPage(
        title,
        json,
        bookStore.book.id,
        store.paperType
      );
    };

    const updatePage = () => {
      updateUserPage(bookStore.userPage.id,json)
    };

    if (!bookStore.userPage) {
      presentAlert({
        header: "Kayıt Adı",
        buttons: [
          {
            text: "Kaydet",
            handler: (e) => {
              save(e[0]);
            },
          },
        ],
        inputs: [
          {
            placeholder: "Kayıt Adı",
          },
        ],
      });
    } else {
      updatePage();
    }
  };

  return (
    <>
      <div
        className="tool-item"
        onClick={() => {
          canvas.clear();
          dispatch(savePage(null));
        }}
      >
        <FaPlus />
      </div>
      <div
        className="tool-item"
        onClick={() => dispatch(setPaperType("lined-paper"))}
      >
        <FaGripLines />
      </div>
      <div
        className="tool-item"
        onClick={() => dispatch(setPaperType("graph-paper"))}
      >
        <FaGripHorizontal />
      </div>
      <div className="tool-item" onClick={() => savePage()}>
        <FaSave />
      </div>
      <div
        className="tool-item"
        onClick={() => dispatch(setDisplayType("page"))}
      >
        <FaTimes />
      </div>
      {bookStore.userPage && (
        <div className="tool-item" onClick={() => deleteUserPage()}>
          <FaTrash />
        </div>
      )}
    </>
  );
};

export default BlankPageTools;
