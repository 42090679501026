
// ** Third Party Components
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { FaFile, FaFileDownload } from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Card, CardBody } from 'reactstrap';
import Avatar from '../../../../components/avatar';
import { getUserPages } from '../../../../database/userPageDatabase';
// ** Styles

const UserPages = (props) => {
  // ** Props
  const { isOpen, sbStore, dispatch, openBookContent, setUserPage, setDisplayType } = props;
  const [userPages, setUserPages] = useState([]);
  useEffect(() => {
    getUserPages(sbStore.book.id).then((res)=> {
      setUserPages(res);
    })
  }, []);

  const handleToggle = () => {
    openBookContent(!isOpen);
  };
  const loadPage= (userPage) => {
      dispatch(setUserPage(userPage))
      dispatch(setDisplayType("blankPage"))
  }

  const renderUserPages = () => {
    return userPages.map((userPage, index) => {
      return (
        <div
          key={userPage.id}
          className="d-flex justify-content-between align-items-center mb-1"
        >
          <div className="d-flex">
            <Avatar
              imgClassName="rounded"
              className="me-75"
              color="primary"
              icon={<FaFile />}
            />
            <div className="my-auto">
              <h6 className="mb-0">{userPage.title}</h6>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Button onClick={()=>loadPage(userPage)} size='sm'><FaFileDownload></FaFileDownload> Yükle</Button>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className={classnames('side-contents d-md-block', {
        open: isOpen,
      })}
    >
      <PerfectScrollbar
        className="book-contents-content"
        options={{ wheelPropagation: false }}
      >
        <div className="book-contents-header px-2 pt-1 pb-0 position-relative">
          <h4 className="mb-0">Kayıtlı Sayfalar {userPages.length}</h4>
          <button
            className="book-contents-close"
            onClick={() => handleToggle()}
          >
            <X />
          </button>
        </div>
        <hr />
        <Card>
          <CardBody>{renderUserPages()}</CardBody>
        </Card>
      </PerfectScrollbar>
    </div>
  );
};

export default UserPages;
