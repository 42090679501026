
import { useEffect, useState } from 'react';
import '../../../../@core/scss/react/libs/noui-slider/noui-slider.scss';
import { MdFlipToBack } from 'react-icons/md';
import { ZoomIn, ZoomOut } from 'react-feather';
const Zoom = ({ setZoom, zoomState }) => {
  const [zoomValue, setZoomValue] = useState();
  useEffect(() => {
    setZoomValue((100 * zoomState.scale) / zoomState.pageScale);
  }, [zoomState]);

  const zoomOut = () => {
    setZoom(zoomValue - 10 / zoomValue);
    setZoomValue(zoomValue - 10);
  };

  const zoomIn = () => {
    setZoom(zoomValue + 10 / zoomValue);
    setZoomValue(zoomValue + 10);
  };
  return (
    <>
      <div className="tool-item" onClick={() => zoomOut()}>
        <ZoomOut />
      </div>
      <div className="tool-item" onClick={() => zoomIn()}>
        <ZoomIn />
      </div>
      <div
        className="tool-item"
        onClick={() => {
          setZoomValue((100 * zoomState.scale) / zoomState.pageScale);
          setZoom(zoomState.zoomFactor);

        }}
      >
        <MdFlipToBack />
        <span>Sıfırla</span>
      </div>

    </>
  );
};

export default Zoom;
