
import { Button } from 'reactstrap';
import { useRef, useState } from 'react';
import { SketchField } from '../../../../@corefabriccanvas';
import Window from '../../../../components/Window';
const AddNote = (props) => {
  const {
    id,
    selectedPosition,
    onNoteWindowClose,
    deleteUserItem,
    smartBookLayout,
    bookId,
    onSave,
    pageNumber,
    userItem,
  } = props;

  const noteSketchRef = useRef();
  const onWindowClose = () => {
    noteSketchRef.current.clear();
    onNoteWindowClose();
  };
  const [sketchSize, setSketchSize] = useState({ w: 300, h: 300 });
  const onResize = (width, height) => {
    setSketchSize({ w: width - 50, h: height - 50 });
  };

  const saveNote = () => {
    const json = JSON.stringify(noteSketchRef.current.toJSON());
    if (id) {
      window.electron.updateUserNote(id, json).then(() => {});
    } else {
      window.electron
        .uploadFile(
          null,
          selectedPosition.x,
          selectedPosition.y,
          bookId,
          pageNumber,
          null,
          json,
          '20'
        )
        .then(() => {
          onSave()
          onNoteWindowClose();
        });
    }
  };

  return (
    <>
      <Window
        title="Not Ekle"
        noMin
        noFull
        width={700}
        height={500}
        onDelete={()=> deleteUserItem(userItem.id)}
        noMax
        onClose={() => onWindowClose()}
        onResize={(width, height) => onResize(width, height)}
      >
        <SketchField
          ref={noteSketchRef}
          value={userItem ? userItem.drawings :""}
          tool={smartBookLayout.currentTool}
          lineColor={smartBookLayout.currentLineColor}
          lineWidth={smartBookLayout.currentThickness}
          height={sketchSize.h}
          width={sketchSize.w}
        />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            alignItems: 'right',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <div className="d-flex justify-content-end">
            <Button onClick={() => noteSketchRef.current.clear()}>
              Temizle
            </Button>
            <Button color="primary" onClick={() => saveNote()} outline>
              Kaydet
            </Button>
            <Button
              onClick={() => onNoteWindowClose(false)}
              className="ms-1"
              color="danger"
              outline
            >
              iptal
            </Button>
          </div>
        </div>
      </Window>
    </>
  );
};

export default AddNote;
