
import { useState } from 'react'
// import Draggable from 'react-draggable'
import ThicknessTool from './components/SizeTool/SizeTool'
import ColorTools from './components/ColorTool/ColorTools'
import ShapeTools from './components/ShapeTool/ShapeTools'
import ToolItem from './components/ToolItem'
import { BsThreeDots } from 'react-icons/bs'
import {
  FaHandPaper,
  FaMousePointer,
  FaPen,
  FaMarker,
  FaFont,
  FaShapes,
  FaEraser,
  FaTrash,
  FaRedo,
  FaUndo,
  FaPlus,
  FaMinus
} from 'react-icons/fa'
import { Tools } from '../../../@corefabriccanvas'
import {
  setTool,
  setLineColor,
  setThickness
} from  "../../../redux/smartBookLayout"
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'

const ToolBox = ({canvas, addText}) => {
  const [showSizeTool, setSizeToolVisibility] = useState(false)
  const [showColorTool, setColorToolVisibility] = useState(false)
  const [showShapesTool, setShapeToolVisibility] = useState(false)
  const smartBookLayout = useSelector((state) => state.smartBookLayout)
  const smartBookStore = useSelector((state) => state.smartBookStore)
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const setSubTool = (type) => {
    switch (type) {
      case 'size':
        setSizeToolVisibility(!showSizeTool)
        setColorToolVisibility(false)
        setShapeToolVisibility(false)
        break
      case 'color':
        setSizeToolVisibility(false)
        setColorToolVisibility(!showColorTool)
        setShapeToolVisibility(false)
        break
      case 'shape':
        setSizeToolVisibility(false)
        setColorToolVisibility(false)
        setShapeToolVisibility(!showShapesTool)
        break
      case 'userContent':
        setSizeToolVisibility(false)
        setColorToolVisibility(false)
        setShapeToolVisibility(false)
        break
      default:
        break
    }
  }

  return (
    // <Draggable cancel=".tool-item">

    // </Draggable>
    <div className={`tool-box scale-${smartBookLayout.toolBoxSize}`}>
    <div className="tool-box-header">
      <BsThreeDots></BsThreeDots>
    </div>
    <>
      <ToolItem
        text="Taşı"
        isActive={smartBookLayout.currentTool === Tools.Pan}
        onClick={() => dispatch(setTool(Tools.Pan))}
      >
        <FaHandPaper />
      </ToolItem>
      <ToolItem
        text="Seç"
        isActive={smartBookLayout.currentTool === Tools.Select}
        onClick={() => {
          dispatch(setTool(Tools.Select))
        }}
      >
        <FaMousePointer />
      </ToolItem>
      <ToolItem
        text="Kalem"
        isActive={smartBookLayout.currentTool === Tools.Pencil}
        onClick={() => {
          dispatch(setTool(Tools.Pencil))
          setSubTool('color')
        }}
      >
        <FaPen />
      </ToolItem>
      <ToolItem
        text="Marker"
        isActive={smartBookLayout.currentTool === Tools.Marker}
        onClick={() => {
          dispatch(setTool(Tools.Marker))
          setSubTool('color')
        }}
      >
        <FaMarker />
      </ToolItem>
      <ToolItem text="Metin" onClick={() => addText()}>
        <FaFont />
      </ToolItem>
      <ToolItem text="Şekiller" onClick={() => setSubTool('shape')}>
        <FaShapes />
      </ToolItem>
      <ToolItem
        text="Silgi"
        isActive={smartBookLayout.currentTool === Tools.Eraser}
        onClick={() => {
          dispatch(setTool(Tools.Eraser))
          setSubTool('size')
        }}
      >
        <FaEraser />
      </ToolItem>
      <ToolItem
        text="Temizle"
        onClick={() => canvas.clear()}
      >
        <FaTrash />
      </ToolItem>
      <ToolItem text="Geri" onClick={() => canvas.undo()}>
        <FaUndo />
      </ToolItem>
      <ToolItem text="İleri" onClick={() => canvas.redo()}>
        <FaRedo />
      </ToolItem>
    </>
    {showSizeTool && (
      <ThicknessTool
        dispatch={dispatch}
        store={smartBookLayout}
        setThickness={setThickness}
      />
    )}
    {showShapesTool && (
      <ShapeTools dispatch={dispatch} className="shapetools" setTool={setTool}></ShapeTools>
    )}
    {showColorTool && (
      <ColorTools
        dispatch={dispatch}
        store={smartBookStore}
        setLineColor={setLineColor}
        setThickness={setThickness}
      ></ColorTools>
    )}
  </div>
  )
}

export default ToolBox
