// SQLITE IMPORTS
import {} from "@capacitor-community/sqlite";
// JSON FILE WITH DATA
import { sqlite } from "../App";

export const getSettings = async (userId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from settings where userId=${userId}`;
    const qValues = await db.query(stmt);
    let userSetting = qValues.values[0];
    if (!userSetting) {
      const stmt = `select * from settings where isDefault=1`;
      const qValues = await db.query(stmt);
      userSetting = qValues.values[0];
    }
    const settings = {
      userId: userSetting.userId,
      showContentInfo: userSetting.showContentInfo === 1,
      pageItemPosition: userSetting.pageItemPosition,
      pageItemIsFull: userSetting.pageItemIsFull === 1,
      toolBoxSize: userSetting.toolBoxSize,
      colors: JSON.parse(userSetting.colors),
      thickness: JSON.parse(userSetting.thicknesses),
    };

    return settings;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const saveSettings = async (
  userId,
  showContentInfo,
  pageItemPosition,
  pageItemIsFull,
  toolBoxSize,
  colors,
  thicknesses
) => {
  const colorStr = JSON.stringify(colors);
  const thicknessStr = JSON.stringify(thicknesses);
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `INSERT INTO settings (userId,showContentInfo,pageItemPosition,pageItemIsFull, toolBoxSize, colors, thicknesses) values
       ('${userId}','${showContentInfo}', '${pageItemPosition}', '${pageItemIsFull}', '${toolBoxSize}', '${colorStr}', '${thicknessStr}') 
      ON CONFLICT(userId) DO UPDATE SET userId='${userId}', showContentInfo= '${showContentInfo}', pageItemPosition='${pageItemPosition}', pageItemIsFull='${pageItemIsFull}', toolBoxSize='${toolBoxSize}',colors='${colorStr}',thicknesses='${thicknessStr}'`;
      await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    const settings = {
      userId: userId,
      showContentInfo: showContentInfo === 1,
      pageItemPosition: pageItemPosition,
      pageItemIsFull: pageItemIsFull === 1,
      toolBoxSize: toolBoxSize,
      colors: colors,
      thickness: thicknesses,
    };
    return settings;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};
