import SizeToolItem from '../SizeTool/SizeToolItem'
import ColorToolItem from './ColorToolItem'

const ColorTools = (props) => {
  const { dispatch, setLineColor, setThickness, store, className } = props
  return (
    <div className={`subTool ${className}`}>
      <div className='border-bottom'>
        <span className="text-dark">Renk Seçimi:</span>
      </div>

      <ul className="list-inline unstyled-list mb-0">
        {store.userSettings.colors.map((color) => {
          return (
            <ColorToolItem
              onClick={() => dispatch(setLineColor(color))}
              color={color}
            />
          )
        })}
      </ul>
      <div className='border-bottom'>
      <span className="text-dark">Kalem Boyutu:</span>
      </div>
      <ul className="list-inline unstyled-list">
        {store.userSettings.thickness.map((size) => {
          return (
            <SizeToolItem
              onClick={() => dispatch(setThickness(parseInt(size, 10)))}
              size={parseInt(size, 10)}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default ColorTools
