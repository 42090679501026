// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';
// ** Axios Imports

export const bookStoreSlice = createSlice({
  name: 'bookStore',
  initialState: {
    books: [],
    classes: [],
    lessons: [],
    filterSpec: {
      title: '',
      status: null,
      classId: 0,
      lessonId: 0,
    },
  },
  reducers: {
    setFilterSpec: (state, action) => {
      state.filterSpec = action.payload;
    },
    setBooks: (state, action) => {
      state.classes= action.payload.classes;
      state.lessons=action.payload.lessons;
      let books = action.payload.books;
      if(state.filterSpec.title !== ""){
        books = books.filter(function (book) {
          return book.title.toLowerCase()
            .includes(state.filterSpec.title.toLowerCase());
        });
      }
      if (state.filterSpec.classId !== 0) {
        books = books.filter(function (book) {
          return book.classIds
            .split(',')
            .includes(state.filterSpec.classId.toString());
        });
      }
      if (state.filterSpec.lessonId !== 0) {
        books = books.filter(function (book) {
          return book.lessonIds
            .split(',')
            .includes(state.filterSpec.lessonId.toString());
        });
      }
      if (state.filterSpec.status !== null) {
        books = books.filter((x) => x.isDownloaded === 1);
      }
      state.books = books;
    },
    setSelectedBook: (state, action) => {
      if (action.payload !== null) {
        const bookId = parseInt(action.payload, 10);
        const selectedBook = state.books.find((x) => x.id === bookId);
        state.book = selectedBook;
      } else {
        state.book = {};
      }
    },
  },
  extraReducers: (builder) => {
  },
});
export const {
  setFilterSpec,
  setBooks,
  setSelectedBook
} = bookStoreSlice.actions;
export default bookStoreSlice.reducer;
