import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { SketchField } from "../@corefabriccanvas";
import { getAllUsers } from "../database/userDatabaseService";
import ExploreContainer from "../components/ExploreContainer";
import "./Home.css";
import Sketch from "./SmartBook/Sketch";
import { useEffect, useState } from "react";
import { Table } from "reactstrap";

var Library = function () {
  const[users, setUsers]=useState([])

  const getAllUsers2 = async ()=>{
    var users= await getAllUsers()
    setUsers(users.values);

  }
  useEffect(()=> {
    getAllUsers2();
  },[])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Blank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Library</IonTitle>
          </IonToolbar>
        </IonHeader>
        <>
        <Table>
          <th>Id</th>
          <th>Email</th>
          <th>Kullanıcı Adı</th>
          <th>IsActive</th>
          {users.map((user)=> (
            <tr>
            <td>{user.id}</td>
            <td>{user.email}</td>
            <td>{user.userTitle}</td>
            <td>{user.isActive}</td>
            </tr>
          ))}
        </Table>
     
        </>
      </IonContent>
    </IonPage>
  );
};

export default Library;
