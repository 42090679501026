/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setBook,
  getBookPage,
  setDisplayType,
  setPageItem,
  setUserSettings,
} from "./store";
import { getBookById } from "../../database/bookDatabaseService";
import {
  setTool,
  handlePageItemSize,
  handlePageItemPosition,
} from "../../redux/smartBookLayout";
import "./styles/main.scss";
import BookPage from "./BookPage";
import PageItem from "./PageItem";
import BlankPage from "./BlankPage";
import LeftSideBar from "./LeftSide";
import LeftSideMobile from "./LeftSide/LeftSideMobile";
import { useParams } from "react-router";
import { getSettings } from "../../database/settingsDatabaseService";
import { getActiveUser } from "../../database/userDatabaseService";
import MobileToolBox from "./ToolBox/MobileToolBox";
const SmartBook = (props) => {
  const sbStore = useSelector((state) => state.smartBookStore);
  const smLayout = useSelector((state) => state.smartBookLayout);
  const [block, setBlock] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  let { id } = useParams();
  useIonViewWillEnter(async () => {
    const getBook = async () => {
      const book = await getBookById(id);
      dispatch(setBook(book));
      var user = await getActiveUser();
      var settings = await getSettings(user.id);
      dispatch(setUserSettings(settings));
    };
    getBook();
  });

  return (
    <IonPage className="sm-canvas">
      <IonContent fullscreen>
        {sbStore.userSettings.showContentInfo && (
          <IonHeader>
            <IonToolbar>
              <IonTitle size="large">{sbStore.contentInfo}</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}

        <div className="sm-content" style={{ height: "100%" }}>
          {/* <div
            className="sidebar-toggle d-block d-lg-none ms-1"
            onClick={() => {
              setSidebarOpen(true);
            }}
          >
            <Menu size="21" />
          </div> */}
          <LeftSideBar
            sidebarOpen={sidebarOpen}
            sbStore={sbStore}
            dispatch={dispatch}
            smLayout={smLayout}
            setUserSettings={setUserSettings}
          />
          <LeftSideMobile
            sbStore={sbStore}
            dispatch={dispatch}
            smLayout={smLayout}
            setUserSettings={setUserSettings}
          />
          <Card className="right-content m-0 p-0">
            {sbStore.displayType === "page" && sbStore.currentPage && (
              <BookPage
                currentPage={sbStore.currentPage}
                isFullWidth={smLayout.isFullWidth}
                block={block}
                setBlock={setBlock}
                setDisplayType={setDisplayType}
                setTool={setTool}
                dispatch={dispatch}
                setPageItem={setPageItem}
              />
            )}
            {sbStore.displayType === "pageItem" && sbStore.currentPageItem && (
              <PageItem
                store={sbStore}
                block={block}
                setBlock={setBlock}
                isFullWidth={smLayout.isFullWidth}
                pageItemIsFull={smLayout.pageItemIsFull}
                pageItemPosition={smLayout.pageItemPosition}
                handlePageItemSize={handlePageItemSize}
                handlePageItemPosition={handlePageItemPosition}
                setDisplayType={setDisplayType}
                setTool={setTool}
                dispatch={dispatch}
                setPageItem={setPageItem}
                getBookPage={getBookPage}
              />
            )}
            {sbStore.displayType === "blankPage" && (
              <BlankPage
                store={sbStore}
                smLayout={smLayout}
                setBlock={setBlock}
                setDisplayType={setDisplayType}
                setTool={setTool}
                dispatch={dispatch}
                setPageItem={setPageItem}
                getBookPage={getBookPage}
              />
            )}
          </Card>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SmartBook;
