import { FaDrawPolygon, FaRegCircle, FaRegSquare, FaSlash} from "react-icons/fa"
import { BsTriangle } from "react-icons/bs"
import { Tools } from "../../../../../@corefabriccanvas"
import ToolItem from "../ToolItem"

const ShapeTools = (props) => {
    const {dispatch, setTool, className } = props
    return (
      <div className={`subTool ${className}`}>
      <ToolItem text="" onClick={() => dispatch(setTool(Tools.Line))}>
          <FaSlash />
        </ToolItem>
        <ToolItem
          text=""
          onClick={() => dispatch(setTool(Tools.Rectangle))}
        >
          <FaRegSquare />
        </ToolItem>
        <ToolItem text="" onClick={() => dispatch(setTool(Tools.Circle))}>
          <FaRegCircle />
        </ToolItem>
        <ToolItem text="" onClick={() => dispatch(setTool(Tools.Triangle))}>
          <BsTriangle />
        </ToolItem>
        <ToolItem text="" onClick={() => dispatch(setTool(Tools.Polygon))}>
          <FaDrawPolygon />
        </ToolItem>
      </div>
    )
}

export default ShapeTools
