import { useSelector } from "react-redux";
import { forwardRef, useRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  collapseBottomTool,
  setLineColor,
  setTool,
  handleZoom,
} from "../../redux/smartBookLayout";
import { SketchField, Tools } from "../../@corefabriccanvas";
import AddNote from "./components/MediaItems/AddNote";
import AddContent from "./components/MediaItems/AddContent";
import { setUserItems } from "./store";
import BottomTool from "./BottomTool";
import ToolBox from "./ToolBox";
import MobileToolBox from "./ToolBox/MobileToolBox";


function Sketch(props, ref) {
  const { dispatch, itemId } = props;

  const smartBookLayout = useSelector((state) => state.smartBookLayout);
  const smartBookStore = useSelector((state) => state.smartBookStore);
  const [previousTool, setPreviousTool] = useState(false, Tools.Pencil);
  const [keyboardValue, setKeyBoardValue] = useState("");
  const [showVirtualKeyboard, setshowVirtualKeyboard] = useState(false);
  const [addingTextIsActive, setAddingTextIsActive] = useState(false);
  const [showAddNoteWindow, setShowAddNoteWindow] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState();
  const [addContentIsActive, setAddContentIsActive] = useState(false);
  const [showAddContentWindow, setShowAddContentWindow] = useState(false);
  const [addedUserContent, setAddedUserContent] = useState("");
  const [itemPosition, setItemPosition] = useState();
  const [penColor, setPenColor] = useState();

  const onMouseDown = (e) => {
    if (addingTextIsActive) {
      ref.current.addText("", e);
      setKeyBoardValue("");
      setshowVirtualKeyboard(true);
    }
  };
  const onSetTool = (tool) => {
    dispatch(setTool(tool))
  };


  const _addText = () => {
    dispatch(setTool(Tools.Select));
    toast.info("Metni Eklemek İstediğiniz Yere Tıklayınız.");
    setTimeout(() => setAddingTextIsActive(true), 500);
  };

  const onUserItemSave = () => {
    window.electron.getUserItems(smartBookStore.book.id).then((items) => {
      dispatch(setUserItems(items));
      dispatch(setTool(Tools.Pencil));
    });
  };

  const onNoteWindowClose = () => {
    setShowAddNoteWindow(false);
    dispatch(setTool(Tools.Pencil));
  };
  const onContentWindowClose = () => {
    setShowAddContentWindow(false);
    dispatch(setTool(Tools.Pencil));
  };

  const setZoom = (zoomScale) => {
    ref.current.zoom(zoomScale);
  };

  const setOpacity = (hex, alpha) =>
    `${hex}${Math.floor(alpha * 255)
      .toString(16)
      .padStart(2, 0)}`;

  useEffect(() => {
    setAddingTextIsActive(false);
    if (
      smartBookLayout.currentTool === "marker" &&
      (penColor === smartBookLayout.currentLineColor || penColor === undefined)
    ) {
      setPenColor(smartBookLayout.currentLineColor);
      const newColor = setOpacity(smartBookLayout.currentLineColor, 0.4);
      dispatch(setLineColor(newColor));
    } else if (
      penColor !== smartBookLayout.currentLineColor &&
      penColor !== undefined
    ) {
      dispatch(setLineColor(penColor));
    }
  }, [smartBookLayout.currentTool]);

  // eslint-disable-next-line no-unused-vars
  const saveData = () => {
    if (ref.current) {
      const json = JSON.stringify(ref.current.toJSON());
      const savedData = JSON.parse(json);
      delete savedData.background;
      delete savedData.backgroundImage;
      const objects = savedData.objects.filter((x) => x.type !== "image");
      savedData.objects = objects;
      localStorage.setItem(
        `${smartBookStore.displayType}-${itemId}`,
        JSON.stringify(savedData)
      );
    }
  };

  const _onSketchChange = () => {
    saveData();
  };


  return (
    <div>
      <SketchField
        tool={smartBookLayout.currentTool}
        height={props.height}
        width={props.width}
        zoomState={smartBookLayout.zoomState}
        onMouseDown={(e) => onMouseDown(e)}
        onSetTool={(tool) => onSetTool(tool)}
        lineColor={smartBookLayout.currentLineColor}
        lineWidth={smartBookLayout.currentThickness}
        handleZoom={(zoom) => {
          dispatch(handleZoom(zoom));
        }}
        onChange={_onSketchChange}
        style={{
          background: props.background,
        }}
        ref={ref}
      >
        {/* {props.children} */}
      </SketchField>

      {ref.current && (
        <>
          <BottomTool
            collapseBottomTool={collapseBottomTool}
            setZoom={(ratio) => {
              ref.current.pinchzoom(ratio, 0, 0);
            }}
            zoomScale={ref.current?.state.zoomFactor}
            fitCanvas={() => ref.current?.fitCanvas()}
            showAllGapFillingAnswer={(showGapFillingAnswer) =>
              ref.current.showAllGapFillingAnswer(showGapFillingAnswer)
            }
            canvas={ref.current}
          />
        </>
      )}

      <ToolBox canvas={ref.current} addText={_addText} />
      {showAddContentWindow && addedUserContent.type !== "0" && (
        <AddContent
          selectedPosition={itemPosition}
          type={addedUserContent.type}
          name={addedUserContent.name}
          bookId={smartBookStore.book.id}
          pageNumber={smartBookStore.currentPage.pageNo}
          setShowAddContentWindow={setShowAddContentWindow}
          setShowAddNoteWindow={setShowAddContentWindow}
          onNoteWindowClose={onContentWindowClose}
          onSave={onUserItemSave}
          dispatch={dispatch}
          setUserItems={setUserItems}
        />
      )}
      {showAddContentWindow && addedUserContent.type === "0" && (
        <AddNote
          selectedPosition={addedUserContent.offset}
          bookId={smartBookStore.book.id}
          pageNumber={smartBookStore.currentPage.pageNo}
          setShowAddNoteWindow={setShowAddNoteWindow}
          smartBookLayout={smartBookLayout}
          onNoteWindowClose={onNoteWindowClose}
          onSave={onUserItemSave}
        />
      )}
    </div>
  );
}

export default forwardRef(Sketch);
