// SQLITE IMPORTS
import {} from "@capacitor-community/sqlite";
// JSON FILE WITH DATA
import { sqlite } from "../App";

export const saveBooks = async (data) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();

    let stmt =
      "INSERT or IGNORE INTO books (id, title, publisherId, publisher,lessonIds,lessonNames, classIds, classNames, coverImage) values ";
    data.books.map((book) => {
      stmt += `('${book.id}', '${book.title}', '${book.publisherId}', '${book.publisher}', '${book.lessonIds}', '${book.lessonNames}', '${book.classIds}', '${book.classNames}', '${book.coverImage}'),`;
    });

    stmt = stmt.slice(0, -1);
    // stmt += "; INSERT or IGNORE INTO class (id, title, displayOrder) values ";

    // data.classList.map((c) => {
    //   stmt += `('${c.id}', '${c.title}', '${c.displayOrder}'),`;
    // });
    // stmt = stmt.slice(0, -1);

    // stmt += "; INSERT or IGNORE INTO lesson (id, title, displayOrder) values ";
    // data.lessonList.map((l) => {
    //   stmt += `('${l.id}', '${l.title}', '${l.displayOrder}'),`;
    // });
    // stmt = stmt.slice(0, -1);
    // debugger

    // query the messages
    // const stmt = `INSERT INTO users (userTitle,accessToken,refreshToken) values ('${userTitle}', '${accessToken}', '${refreshToken}')`;
    await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return stmt;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const saveBookUnits = async (book) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    let stmt =
      "INSERT or replace INTO unit (id, name, bookName, startPage, endPage, bookId, parentId) values";
    book.units.map((unit) => {
      stmt += `('${unit.id}', '${unit.name}', '${unit.bookName}', '${unit.startPage}', '${unit.endPage}', '${unit.bookId}', null),`;
    });

    book.units.map((unit) => {
      unit.subUnits.map((subUnit) => {
        stmt += `('${subUnit.id}', '${subUnit.name}', '${unit.bookName}', '${subUnit.startPage}', '${subUnit.endPage}', '${unit.bookId}', '${subUnit.unitId}'),`;
      });
    });

    stmt = stmt.slice(0, -1);
    await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return null;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const saveBookTests = async (book) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    let stmt =
      " INSERT or replace INTO test (id, name, startPage, endPage, unitId, displayOrder) values";
    book.units.map((unit) => {
      unit.unitContentModels.map((test) => {
        stmt += `('${test.id}', '${test.name}', '${test.startPage}', '${test.endPage}','${test.unitId}','${test.displayOrder}'),`;
      });
    });

    stmt = stmt.slice(0, -1);
    await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return null;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const saveBook = async (book) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();

    let stmt =
      "INSERT or replace INTO page (id, bookId, pageNo, fileId) values ";
    book.pages.map((page) => {
      stmt += `('${page.id}', '${page.bookId}', '${page.pageNo}', '${page.fileId}'),`;
    });

    stmt = stmt.slice(0, -1);

    await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return null;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const setBookDownloadedStatus = async (bookId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `update books set isDownloaded=1 where id=${bookId};`;
    const qValues = await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return qValues.values;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const deleteBook = async (bookId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    let stmt = `update books set isDownloaded=0 where id=${bookId};`;
    stmt += `delete from page where bookId=${bookId}`;
    const qValues = await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return qValues.values;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const getClassAndLessons = async () => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from class`;
    const classList = await db.query(stmt);
    await sqlite.closeConnection("smartBook");
    return { classList, lessonList: [{ tt: 1 }] };
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const getLessons = async () => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from lesson`;
    const qValues = await db.query(stmt);
    await sqlite.closeConnection("smartBook");
    return qValues.values;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const getBookById = async (bookId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from books where id=${bookId}`;
    const qValues = await db.query(stmt);
    const book = qValues.values[0];
    book.pages = await getBookPages(bookId);
    book.units = await getBookUnits(bookId);
    await sqlite.closeConnection("smartBook");

    return book;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const getBookUnits = async (bookId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from unit where bookId=${bookId}`;
    const qValues = await db.query(stmt);
    const units = qValues.values;
    for (let i = 0; i < units.length; i++) {
      const testsQuery = `select * from test where unitId=${units[i].id}`;
      const tests = await db.query(testsQuery);
      units[i].unitContents= tests.values;
    }
  
    return units;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};
export const getBookPages = async (bookId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from page where bookId=${bookId}`;
    const qValues = await db.query(stmt);
    const pages = qValues.values;
    return qValues.values;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const getSubPageItems = async (pageItemId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const query = `select * from pageItems where parentId=${pageItemId}`;
    const qValues = await db.query(query);
    return qValues.values;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};
export const getPageItems = async (pageId) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const query = `select * from pageItems where pageId=${pageId} and parentId="undefined"`;
    const qValues = await db.query(query);
    const pageItems = qValues.values;
    for (let item of pageItems) {
      item.options = await getSubPageItems(item.id);
    }
    return pageItems;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};
export const getAllBooks = async () => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const booksQuery = `select * from books`;
    const books = await db.query(booksQuery);
    const classesQuery = "select * from class";
    const classes = await db.query(classesQuery);
    const lessonsQuery = "select * from lesson";
    const lessons = await db.query(lessonsQuery);
    return { books, classes, lessons };
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};
