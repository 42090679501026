import { Input, Label } from "reactstrap";

const PageItemPosition = ({pageItemPosition, setPageItemPosition}) => {
  return (
    <ul className="list-unstyled user-status">
      <li className="pb-1">
        <div className="form-check">
          <Input
            type="radio"
            label="Sola Yaslı"
            id="user-online"
            checked={pageItemPosition === 'left'}
            onChange={() => setPageItemPosition('left')}
          />
          <Label className="form-check-label" for="left">
          Sola Yaslı
          </Label>
        </div>
      </li>
      <li className="pb-1">
        <div className="form-check">
          <Input
            type="radio"
            label="Ortalı"
            id="center"
            checked={pageItemPosition === 'center'}
            onChange={() => setPageItemPosition('center')}
          />
          <Label className="form-check-label" for="center">
            Ortalı
          </Label>
        </div>
      </li>
      <li className="pb-1">
        <div className="form-check">
          <Input
            type="radio"
            label="Sağa Yaslı"
            id="right"
            checked={pageItemPosition === 'right'}
            onChange={() => setPageItemPosition('right')}
          />
          <Label className="form-check-label" for="right">
            Sağa Yaslı
          </Label>
        </div>
      </li>

    </ul>
  );
};

export default PageItemPosition
