// ** Third Party Components
import classnames from "classnames";
import { useState, useEffect } from "react";

// ** Reactstrap Imports
import { Card, CardBody, Input, Label } from "reactstrap";
import { getClassAndLessons } from "../../database/bookDatabaseService";

const Sidebar = (props) => {
  // ** Props
  const { sidebarOpen, dispatch, setFilterSpec, store } = props;

  useEffect(() => {
    const setClassAndLessons = async () => {
      var result = await getClassAndLessons();
    };
    setClassAndLessons();
  }, [store]);

  const filterBook = ({ lessonId, classId, status }) => {
    if (lessonId !== undefined) {
      dispatch(setFilterSpec({ ...store.filterSpec, lessonId }));
    }
    if (classId !== undefined) {
      dispatch(setFilterSpec({ ...store.filterSpec, classId }));
    }
    if (status !== undefined) {
      dispatch(setFilterSpec({ ...store.filterSpec, status }));
    }
  };

  return (
    <>
      <div className="sidebar-detached sidebar-left">
        <div className="sidebar">
          <div
            className={classnames("sidebar-shop", {
              show: sidebarOpen,
            })}
          >
            <Card>
              <CardBody>
                <div id="product-categories">
                  <h6 className="filter-title">Sınıflar</h6>
                  <ul className="list-unstyled categories-list">
                    <li>
                      <div className="form-check">
                        <Input
                          type="radio"
                          id="branch-all"
                          name="branch-radio"
                          defaultChecked
                          onChange={() => filterBook({ classId: 0 })}
                        />
                        <Label className="form-check-label" for="branch-all">
                          Tümü
                        </Label>
                      </div>
                    </li>
                    {store.classes.map((branch) => {
                      return (
                        <li key={`branch-${branch.id}`}>
                          <div className="form-check">
                            <Input
                              type="radio"
                              id={`branch-${branch.id}`}
                              name="branch-radio"
                              onChange={() =>
                                filterBook({ classId: branch.id })
                              }
                            />
                            <Label
                              className="form-check-label"
                              for={`branch-${branch.id}`}
                            >
                              {branch.title}
                            </Label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="brands">
                  <h6 className="filter-title">Dersler</h6>
                  <ul className="list-unstyled categories-list">
                    <li>
                      <div className="form-check">
                        <Input
                          type="radio"
                          id="lesson-all"
                          name="lesson-radio"
                          defaultChecked
                          onChange={() => filterBook({ lessonId: 0 })}
                        />
                        <Label className="form-check-label" for="lesson-all">
                          Tümü
                        </Label>
                      </div>
                    </li>
                    {store.lessons.map((lesson) => {
                      return (
                        <li key={`lesson-${lesson.id}`}>
                          <div className="form-check">
                            <Input
                              type="radio"
                              id={`lesson-${lesson.id}`}
                              name="lesson-radio"
                              onChange={() =>
                                filterBook({ lessonId: lesson.id })
                              }
                            />
                            <Label
                              className="form-check-label"
                              for={`lesson-${lesson.id}`}
                            >
                              {lesson.title}
                            </Label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="brands">
                  <h6 className="filter-title">Filtrele</h6>
                  <ul className="list-unstyled categories-list">
                    <li>
                      <div className="form-check">
                        <Input
                          type="radio"
                          id="all"
                          name="status-radio"
                          defaultChecked
                          onChange={() => filterBook({ status: null })}
                        />
                        <Label className="form-check-label" for="all">
                          Tümü
                        </Label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <Input
                          type="radio"
                          id="downloaded"
                          name="status-radio"
                          onChange={() => filterBook({ status: 1 })}
                        />
                        <Label className="form-check-label" for="downloaded">
                          İndirilenler
                        </Label>
                      </div>
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default Sidebar;
