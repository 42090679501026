// ** Third Party Components
import classnames from "classnames";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Card, CardBody, Label, Input } from "reactstrap";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import InputNumber from "rc-input-number";
import { Plus, Minus } from "react-feather";
import ColorItem from "./ColorItem";
import ThicknessInput from "./Thickness";
import "../../../../@core/scss/react/libs/input-number/input-number.scss";
import PageItemPosition from "./PageItemPosition";
import { saveSettings } from "../../../../database/settingsDatabaseService";
import { getActiveUser } from "../../../../database/userDatabaseService";
// ** Styles

const SbSettings = (props) => {
  // ** Props
  const { isOpen, sbStore, dispatch, setUserSettings, openSettings } = props;
  const [colors, setColors] = useState(sbStore.userSettings.colors);
  const [thiknesses, setThiknesses] = useState(sbStore.userSettings.thickness);
  const [pickerColor, setPickerColor] = useState();
  const [showColorPicker, setShowColorPicker] = useState("");
  const [colorIndex, setColorIndex] = useState(0);

  const [pageItemPosition, setPageItemPosition] = useState(
    sbStore.userSettings.pageItemPosition
  );
  const [pageItemIsFull, setPageItemIsFull] = useState(sbStore.userSettings.pageItemIsFull);
  const [showContentInfo, setShowContentInfo] = useState(sbStore.userSettings.showContentInfo);
  const [toolSize, setToolSize] = useState(sbStore.userSettings.toolBoxSize);

  const handleToggle = () => {
    openSettings(!isOpen);
  };

  const openColorPicker = (index, color) => {
    setShowColorPicker(!showColorPicker);
    setColorIndex(index);
    setPickerColor(color);
  };

  const updateColors = (color) => {
    const newColors = [...colors];
    newColors[colorIndex] = color;
    setColors(newColors);
    setPickerColor(color);
  };

  const updateThicknesses = (thickness, index) => {
    const newThicknesses = [...thiknesses];
    newThicknesses[index] = thickness;
    setThiknesses(newThicknesses);
  };

  const saveUserSettings = async () => {
    const user = await getActiveUser();
    saveSettings(
      user.id,
      showContentInfo === true ? 1 : 0,
      pageItemPosition,
      pageItemIsFull === true ? 1 : 0,
      toolSize,
      colors,
      thiknesses
    ).then((res)=> {
      dispatch(setUserSettings(res))
    });
  };

  const styles = reactCSS({
    default: {
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const renderColors = () => {
    return colors.map((color, index) => {
      return (
        <ColorItem
          index={index}
          color={color}
          setShowColorPicker={setShowColorPicker}
          openColorPicker={openColorPicker}
        ></ColorItem>
      );
    });
  };

  const renderThickness = () => {
    return thiknesses.map((thikness, index) => {
      return (
        <ThicknessInput
          updateThicknesses={updateThicknesses}
          value={parseInt(thikness, 10)}
          index={index}
        ></ThicknessInput>
      );
    });
  };

  return (
    <div
      className={classnames("side-contents d-md-block", {
        open: isOpen,
      })}
    >
      <PerfectScrollbar
        className="book-contents-content"
        options={{ wheelPropagation: false }}
      >
        <div className="book-contents-header px-2 pt-1 pb-0 position-relative">
          <h4 className="mb-0">Ayarlar</h4>
          <button
            className="book-contents-close"
            onClick={() => handleToggle()}
          >
            <FaTimes />
          </button>
        </div>
        <hr />
        <Card>
          <CardBody>
            <div className="mb-1">
              <Label className="form-label" for="value">
                Seçilebilir Renkler
              </Label>
              <div>
                {renderColors()}
                {showColorPicker ? (
                  <div style={styles.popover}>
                    <div
                      style={styles.cover}
                      onClick={() => setShowColorPicker(!showColorPicker)}
                    />
                    <SketchPicker
                      color={pickerColor}
                      onChangeComplete={(color) => updateColors(color.hex)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mb-1">
              <Label className="form-label" for="value">
                Seçilebilir Kalınlıklar
              </Label>
              <div className="d-flex">{renderThickness()}</div>
            </div>
            <div className="mb-1">
              <Label className="form-label" for="value">
                Sunum Modunda İçerikler Ekrana Sığdırılsın mı?
              </Label>
              <div className="mb-1">
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    defaultChecked={pageItemIsFull}
                    onChange={() => setPageItemIsFull(!pageItemIsFull)}
                    id="basic-cb-checked"
                  />
                  <Label for="basic-cb-checked" className="form-check-label">
                    Evet
                  </Label>
                </div>
              </div>
            </div>
            <div className="mb-1">
              <Label className="form-label" for="value">
                Sunum Modunda İçeriklerin Pozisyonu
              </Label>
              <PageItemPosition
                setPageItemPosition={setPageItemPosition}
                pageItemPosition={pageItemPosition}
              ></PageItemPosition>
            </div>
            <div className="mb-1">
              <Label className="form-label" for="value">
                İçerik Bilgisi Gösterilsin mi?
              </Label>
              <div className="mb-1">
                <div className="form-check form-check-inline">
                  <Input
                    type="checkbox"
                    defaultChecked={showContentInfo}
                    onChange={() => setShowContentInfo(!showContentInfo)}
                    id="basic-cb-checked"
                  />
                  <Label for="basic-cb-checked" className="form-check-label">
                    Evet
                  </Label>
                </div>
              </div>
            </div>
            <div className="mb-1">
              <Label className="form-label" for="value">
                Araç Çubuğu Boyutu
              </Label>
              <InputNumber
                defaultValue={toolSize}
                min={1}
                max={6}
                onChange={(v) => setToolSize(v)}
                upHandler={<Plus />}
                downHandler={<Minus />}
                className="me-1 input-primary"
              />{" "}
            </div>
            <Button
              className="me-1"
              color="primary"
              onClick={() => saveUserSettings()}
            >
              Kaydet
            </Button>
            <Button color="danger" outline>
              İptal
            </Button>
          </CardBody>
        </Card>
      </PerfectScrollbar>
    </div>
  );
};

export default SbSettings;
