/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool"

const fabric = require("fabric").fabric

class DefaultTool extends FabricCanvasTool {
  configureCanvas(props) {
    const canvas = this._canvas
    canvas.isDrawingMode = canvas.selection = false
    // eslint-disable-next-line no-return-assign
    canvas.forEachObject((o) => (o.selectable = o.evented = false))
    canvas.discardActiveObject()
    canvas.defaultCursor = "pointer"
    canvas.renderAll()
  }
}

export default DefaultTool
