/* eslint-disable no-undef */

if (typeof Object.assign !== "function") {
  Object.defineProperty(Object, "assign", {
    // eslint-disable-next-line no-unused-vars
    value: function assign(target, varArgs) {
      "use strict"
      if (target === null) {
        throw new TypeError("Cannot convert undefined or null to object")
      }
       to = Object(target)
      for (let index = 1; index < arguments.length; index++) {
         nextSource = arguments[index]
        if (nextSource !== null) {
          for (nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true
  })
}
