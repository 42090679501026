import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import { getClassAndLessons } from '../database/bookDatabaseService';
import { useEffect, useState } from 'react';
var Home = function () {
  const [classes, setClasses] = useState("")

  const GetData= async()=> {
   var classes= await getClassAndLessons();
   setClasses(JSON.stringify(classes))
  }
  useEffect(()=> {
    GetData();
  },[])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Blank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Blank</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>{classes}</div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
