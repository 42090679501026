import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { forwardRef, useState } from "react";
import { Collapse, Row, Col } from "reactstrap";
import {
  handlePageIsFullWidth,
  handlePageItemPosition,
  handlePageItemSize,
  handleZoom,
  setPaperType,
} from "../../../redux/smartBookLayout";
import { useDispatch, useSelector } from "react-redux";
import DisplayType from "./components/DisplayType";
import PageNagation from "./components/PageNavigation";
import TestTool from "./components/TestTool";
import Zoom from "./components/Zoom";
import {
  setCurrentPageItem,
  setDisplayType,
  getBookPage,
  next,
  previous,
  setCurrentTest,
  setUserPage,
} from "../store";
import "./bottom-tool.scss";
import BlankPageTools from "./components/BlankPageTools";
import { setCurrentPage } from "../store";
import LeftSideMobile from "../LeftSide/LeftSideMobile";
import MobileToolBox from "../ToolBox/MobileToolBox";
const BottomTool = (
  {
    collapseBottomTool,
    setZoom,
    zoomScale,
    fitCanvas,
    showAllGapFillingAnswer,
    canvas,
  },
  ref
) => {
  const dispatch = useDispatch();
  // const layoutStore = useSelector((state) => state.smartBookLayout)
  const smartBookStore = useSelector((state) => state.smartBookStore);
  const layoutStore = useSelector((state) => state.smartBookLayout);

  const [isOpen, setIsOpen] = useState(true);

  // const next = () => {
  //   var bookPages= smartBookStore.book.pages;
  //   var nextPage= bookPages.find(x=>x.pageNo===smartBookStore.currentPage.pageNo+1);
  //   dispatch(setCurrentPage(nextPage))
  // }

  // const previous = () => {
  //   var bookPages= smartBookStore.book.pages;
  //   var previousPage= bookPages.find(x=>x.pageNo===smartBookStore.currentPage.pageNo-1);
  //   dispatch(setCurrentPage(previousPage))
  // }

  const getBookPage = () => {};

  return (
    <div className="bottom-tool-box" ref={ref}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="collapse-button text-center"
      >
        {!isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="bottom-tools">
          <Row>
            <Col lg="4" sm="0" className="d-flex justify-content-end hidden-xs">
              <DisplayType
                dispatch={dispatch}
                isPageFullWidth={layoutStore.isPageFullWidth}
                handlePageIsFullWidth={handlePageIsFullWidth}
                setDisplayType={setDisplayType}
                displayType={smartBookStore.displayType}
                currentPage={smartBookStore.currentPage}
                setCurrentPageItem={setCurrentPageItem}
                setUserPage={setUserPage}
                currentPageItems={smartBookStore.currentPageItems}
              />
              <Zoom
                setZoom={(e) => setZoom(e)}
                zoomState={layoutStore.zoomState}
                zoomScale={zoomScale}
                fitCanvas={fitCanvas}
                dispatch={dispatch}
                handleZoom={handleZoom}
              />
            </Col>
            <Col lg="4" md="12" sm="12" className="d-flex justify-content-center">
              <PageNagation
                dispatch={dispatch}
                next={() => dispatch(next())}
                previous={() => dispatch(previous())}
                getBookPage={getBookPage}
                showAllGapFillingAnswer={showAllGapFillingAnswer}
                store={smartBookStore}
              />
            </Col>
            <Col lg="4" md="5" sm="0" className="d-flex">
              {smartBookStore.displayType === "pageItem" && (
                <TestTool
                  dispatch={dispatch}
                  next={() => dispatch(next())}
                  previous={() => dispatch(previous())}
                  handlePageItemPosition={handlePageItemPosition}
                  handlePageItemSize={handlePageItemSize}
                  setCurrentTest={setCurrentTest}
                  showAllGapFillingAnswer={showAllGapFillingAnswer}
                  getBookPage={getBookPage}
                  store={smartBookStore}
                  layoutStore={layoutStore}
                />
              )}
              {smartBookStore.displayType === "blankPage" && (
                <>
                  <BlankPageTools
                    dispatch={dispatch}
                    store={layoutStore}
                    bookStore={smartBookStore}
                    setDisplayType={setDisplayType}
                    setPaperType={setPaperType}
                    canvas={canvas}
                  />
                </>
              )}
            </Col>
            <MobileToolBox canvas={canvas}></MobileToolBox>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default forwardRef(BottomTool);
