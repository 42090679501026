/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import Window from '../../../../../components/Window';
const PixeBayImage = ({ item }) => {
  const [showImage, setShowImage] = useState(false);
  return (
    <>
      <Card onClick={() => setShowImage(!showImage)}>
        <CardBody className="text-center">
          <img
            className="img-fluid"
            alt="Not authorized page"
            src={`${item.previewURL}`}
          />
        </CardBody>
      </Card>
      {showImage && (
        <Window
          title="Resim"
          width={900}
          noDelete
          height={400}
          noMax
          onClose={() => console.log()}
          onResize={(width, height) =>console.log()}
        >
          <img alt="" className="img-fluid card-img-top mt-1" src={item.largeImageURL} />
        </Window>
      )}
    </>
  );
};

export default PixeBayImage;
