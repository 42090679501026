import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {
  defineCustomElements as jeepSqlite,
  applyPolyfills,
  JSX as LocalJSX,
} from "jeep-sqlite/loader";
import { HTMLAttributes } from "react";
import { Capacitor } from "@capacitor/core";
import {
  CapacitorSQLite,
  SQLiteConnection,
  SQLiteDBConnection,
} from "@capacitor-community/sqlite";
import "./@core/scss/core.scss";

type StencilToReact<T> = {
  [P in keyof T]?: T[P] &
    Omit<HTMLAttributes<Element>, "className"> & {
      class?: string;
    };
};

declare global {
  export namespace JSX {
    interface IntrinsicElements
      extends StencilToReact<LocalJSX.IntrinsicElements> {}
  }
}

applyPolyfills().then(() => {
  jeepSqlite(window);
});
window.addEventListener("DOMContentLoaded", async () => {
  console.log("$$$ in index $$$");
  const platform = Capacitor.getPlatform();
  const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);
  try {
    if (platform === "web") {
      const jeepEl = document.createElement("jeep-sqlite");
      document.body.appendChild(jeepEl);
      await customElements.whenDefined("jeep-sqlite");
      await sqlite.initWebStore();
    }
    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection("smartBook", false)).result;
    var db: SQLiteDBConnection;
    if (ret.result && isConn) {
      db = await sqlite.retrieveConnection("smartBook", false);
    } else {
      db = await sqlite.createConnection(
        "smartBook",
        false,
        "no-encryption",
        1,
        false
      );
    }

    await db.open();
    let query = `
    CREATE TABLE IF NOT EXISTS users (
      id INTEGER PRIMARY KEY NOT NULL,
      email TEXT NOT NULL UNIQUE,
      userTitle TEXT NOT NULL,
      accessToken TEXT NOT NULL,
      refreshToken TEXT NOT NULL,
      isActive INTEGER
    );

    CREATE TABLE IF NOT EXISTS class (
      id INTEGER PRIMARY KEY NOT NULL,
      title TEXT,
      displayOrder INTEGER );

      CREATE TABLE IF NOT EXISTS lesson (
        id INTEGER PRIMARY KEY NOT NULL ,
        title TEXT,
        displayOrder INTEGER );

      CREATE TABLE IF NOT EXISTS books (
      id INTEGER PRIMARY KEY NOT NULL,
      title TEXT NOT NULL,
      publisherId INTEGER,
      publisher TEXT,
      lessonIds TEXT,
      lessonNames TEXT,
      classIds TEXT,
      classNames TEXT,
      coverImage TEXT,
      createOn TEXT,
      isDownloaded INTEGER
    );

    CREATE TABLE IF NOT EXISTS page (
      id INTEGER PRIMARY KEY NOT NULL,
      bookId INTEGER NOT NULL,
      pageNo INTEGER NOT NULL,
      fileId INTEGER NOT NULL,
      image TEXT
    );

    CREATE TABLE IF NOT EXISTS unit (
      id INTEGER primary key not null,
      name TEXT,
      bookName TEXT,
      startPage INTEGER,
      endPage INTEGER,
      parentId INTEGER,
      bookId INTEGER );

      CREATE TABLE IF NOT EXISTS test (
        id INTEGER primary key not null,
        name TEXT,
        startPage INTEGER,
        endPage INTEGER,
        unitId INTEGER,
        displayOrder INTEGER);

        CREATE TABLE IF NOT EXISTS pageItems (
          id INTEGER primary key not null,
          title TEXT,
          correctAnswer TEXT,
          pageId INTEGER, 
          pageNo INTEGER, 
          axisX real, 
          axisY real, 
          width real, 
          height real, 
          imageWidth real,
          imageHeight real,
          rootAxisX real,
          rootAxisY real,
          rootWidth real,
          rootHeight real,
          rootPageNo int,
          rootImageWidth real,
          rootImageHeight real,
          isCorrect int, 
          imageBytes image,
          solutionImage image,
          solutionVideo Text,
          pageItemType int,
          parentId int, 
          contentId int,
          activityLink Text,
          displayOrder int);

          CREATE TABLE IF NOT EXISTS settings (
            id INTEGER primary key not null,
            userId INTEGER UNIQUE,
            showContentInfo INTEGER,
            pageItemPosition INTEGER,
            pageItemIsFull INTEGER,
            toolBoxSize INTEGER,
            colors TEXT,
            thicknesses TEXT);
            
            CREATE TABLE IF NOT EXISTS colors (
              id INTEGER primary key not null,
              userId INTEGER UNIQUE,
              colors TEXT
             );

             CREATE TABLE IF NOT EXISTS thicknesses (
              id INTEGER primary key not null,
              userId INTEGER UNIQUE,
              thicknesses TEXT
             );

             CREATE TABLE IF NOT EXISTS userPage (
              id INTEGER primary key AUTOINCREMENT,
              title TEXT,
              json TEXT,
              bookId INTEGER,
              paperType TEXT)`;

    const res: any = await db.execute(query);
    console.log(`res: ${JSON.stringify(res)}`);
    await db.close();
    await sqlite.closeConnection("smartBook", false);

    const container = document.getElementById("root");
    const root = createRoot(container!);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  } catch (err) {
    console.log(`Error: ${err}`);
    throw new Error(`Error: ${err}`);
  }
});
