/* eslint-disable jsx-a11y/media-has-caption */
import { Card, CardBody } from 'reactstrap';
import PixeBayVideo from './components/Video';

const PixabayVideoResult = ({ searchResults }) => {
  return (
    <>
      {searchResults.data.hits.map((item) => (
        <PixeBayVideo item={item} />
      ))}
    </>
  );
};

export default PixabayVideoResult;
