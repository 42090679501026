
import ReactPlayer from 'react-player'
import Window from '../../../../components/Window';
const VideoWindow = (props) => {
  const { filePath, id, deleteUserItem, onWindowClose, title } = props;
  const onResize = () => {};
  return (
    <>
      <Window
        title={title || "Video"}
        width={700}
        noDelete
        height={400}
        noMax
        onClose={() => onWindowClose()}
        onResize={(width, height) => onResize(width, height)}
        onDelete= {()=> deleteUserItem(id)}
      >
         <ReactPlayer
            className='w-100 h-100'
            controls
            url={filePath}
           />
      </Window>
    </>
  );
};

export default VideoWindow;
