// SQLITE IMPORTS
import {} from "@capacitor-community/sqlite";
// JSON FILE WITH DATA
import { sqlite } from "../App";

export const savePages = async (pages) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    let stmt =
      "INSERT or replace INTO page (id, bookId, pageNo, fileId, image) values ";
      pages.map((page) => {
      stmt += `('${page.id}', '${page.bookId}', '${page.pageNo}', '${page.fileId}','${page.pageImage}' ),`;
    });

    stmt = stmt.slice(0, -1);

    await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return null;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const SavePageItems = async (pageItems) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    let stmt =
      "INSERT or replace INTO pageItems (id, title, correctAnswer, pageId, pageNo, axisX, axisY, width, height, imageWidth, imageHeight, rootAxisX, rootAxisY, rootWidth, rootHeight, rootPageNo, rootImageWidth, rootImageHeight, isCorrect, imageBytes, solutionImage, solutionVideo, pageItemType, parentId, contentId, activityLink, displayOrder) values ";
      pageItems.map((item) => {
      stmt += `('${item.id}', 
      '${item.title}',
      '${item.correctAnswer}', 
      '${item.pageId}',
      '${item.pageNo}',
      '${item.position?.axisX}',
      '${item.position?.axisY}',
      '${item.position?.width}',
      '${item.position?.height}',
      '${item.position?.imageWidth}',
      '${item.position?.imageHeight}',
      '${item.position?.rootAxisX}',
      '${item.position?.rootAxisY}',
      '${item.position?.rootWidth}',
      '${item.position?.rootHeight}',
      '${item.position?.rootPageNo}',
      '${item.position?.rootImageWidth}',
      '${item.position?.rootImageHeight}',
      '${item.position?.isCorrect}',
      '-',
      '${item.solutionImage}',
      '${item.solutionVideo}',
      '${item.pageItemType}',
      '${item.parentId}',
      '${item.contentId}',
      '${item.activityLink}',
      '${item.order}'
      ),`;
    });
    stmt = stmt.slice(0, -1);
    await db.query(stmt);
    await db.close();
    return null;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};