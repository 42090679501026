/* eslint-disable no-var */
/* eslint-disable no-undef */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiConfig } from '../configs/apiconfig';

export const login = createAsyncThunk(
  'authentication/login',
  async (...params) => {
    // const response = axios.post(apiConfig.apiAdress+apiConfig.loginEndpoint, ...params)
  // MEB
   const response= axios.request({
      url: apiConfig.loginEndpoint,
      method: "post",
      baseURL: apiConfig.apiAdress,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
         },
      data: `username=${params[0].userNameOrEmail}&password=${params[0].password}&rememberme=true&grant_type=password`
    })

    return response;
  }
);

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    accessToken: '',
  },
  reducers: {
    setUser: (state, action) => {
      state.user= {
        userTitle: action.payload.title,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      }
    },
    updateTokens: (state, action) => {
      // state.accessToken = action.payload.accessToken;
      // state.refreshToken = action.payload.refreshToken;
    },
    handleLogout: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
    });
  },
});

export const {
  setUser,
  updateTokens,
  handleLogout,
} = authSlice.actions;

export default authSlice.reducer;