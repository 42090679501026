
import FabricCanvasTool from "../fabrictool"
import { fabric } from "fabric"

class Eraser extends FabricCanvasTool {
  configureCanvas(props) {
    this._canvas.freeDrawingBrush = new fabric.EraserBrush(this._canvas)
    this._canvas.freeDrawingBrush.width = props.lineWidth * 5
    this._canvas.isDrawingMode = true
  }
}

export default Eraser
