
import classnames from "classnames"

const ToolItem = (props) => {
  return (
    <button onClick={() => props.onClick()} className={classnames('tool-item', {
      active: props.isActive
    })}>
       {props.children}
      {/* <span>{props.text}</span> */}
    </button>
  )
}

export default ToolItem
