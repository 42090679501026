// SQLITE IMPORTS
import {} from "@capacitor-community/sqlite";
// JSON FILE WITH DATA
import { sqlite } from "../App";
export const saveUserPage = async (title, json, bookId, paperType) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    let stmt = `INSERT or IGNORE INTO userPage (title, json, bookId, paperType) values ('${title}', '${json}', '${bookId}', '${paperType}')`;
    await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return stmt;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const updateUserPage = async (id, json) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    let stmt = `Update userPage set json='${json}' where id='${id}'`;
    await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return stmt;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const getUserPages = async (bookId) => {
    let isConn = await sqlite.isConnection("smartBook");
    let db;
    try {
      if (!isConn.result) {
        db = await sqlite.createConnection("smartBook");
      } else {
        db = await sqlite.retrieveConnection("smartBook");
      }
      await db.open();
      // query the messages
      const stmt = `select * from userPage where bookId=${bookId}`;
      const userPages = await db.query(stmt);
      await sqlite.closeConnection("smartBook");
      return  userPages.values;
    } catch (err) {
      return Promise.reject(`Error: ${err}`);
    }
  };
  

