


import React, { useState }  from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { SQLiteHook, useSQLite } from 'react-sqlite-hook';
import { AxiosInterceptor } from "./utility/axios";
import { Provider } from "react-redux";
import store from "./redux/store";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import "./theme/variables.css";
import Login from "./pages/Login";
import Library from "./pages/Library";
import Books from "./pages/Books";
import SmartBook from './pages/SmartBook';
/* Theme variables */
import './theme/variables.css';
import { createStore } from './data/IonicStorage';
import Home from './pages/Home';

interface JsonListenerInterface {
  jsonListeners: boolean,
  setJsonListeners: React.Dispatch<React.SetStateAction<boolean>>,
}
interface existingConnInterface {
  existConn: boolean,
  setExistConn: React.Dispatch<React.SetStateAction<boolean>>,
}

// Singleton SQLite Hook
export let sqlite: SQLiteHook;
// Existing Connections Store
export let existingConn: existingConnInterface;
// Is Json Listeners used
export let isJsonListeners: JsonListenerInterface;

setupIonicReact();

const App: React.FC = () => {
  const [existConn, setExistConn] = useState(false);
  existingConn = {existConn: existConn, setExistConn: setExistConn};

  // !!!!! if you do not want to use the progress events !!!!!
  // since react-sqlite-hook 2.1.0
  // sqlite = useSQLite()
  // before
  // sqlite = useSQLite({})
  // !!!!!                                               !!!!!

  sqlite = useSQLite();
  createStore("smart-library-db");

  console.log(`$$$ in App sqlite.isAvailable  ${sqlite.isAvailable} $$$`);


  return (
    <IonApp>
    <Provider store={store}>
      <AxiosInterceptor>
        <IonReactRouter>
          <IonRouterOutlet>
            {/* <Route path="/home" component={Home} exact={true} /> */}
            <Route path="/login" component={Login} exact={true} />
            <Route path="/books" component={Books} exact={true} />
            <Route path="/home" component={Home} exact={true} />
            <Route path="/library" component={Library} exact={true} />
            <Route path="/smartbook/:id" component={SmartBook} exact={true} />
            <Route exact path="/" render={() => <Redirect to="/login" />} />
          </IonRouterOutlet>
        </IonReactRouter>
      </AxiosInterceptor>
    </Provider>
  </IonApp>
  );
};

export default App;