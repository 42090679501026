import { useEffect, useRef } from "react";
import Sketch from "./Sketch";
import { useResize } from "../../utility/hooks/useResize";
import { setPaperType } from "../../redux/smartBookLayout";
import "./styles/blankpage.scss";
const BlankPage = ({
  store,
  smLayout,
  dispatch,
  setUserPage,
  setDisplayType,
  setTool,
  setPageItem,
  getBookPage,
}) => {
  const componentRef = useRef();
  const ref = useRef();
  const size = useResize(componentRef);

  useEffect(() => {
    if (store.userPage) {
      ref.current.fromJSON(store.userPage.json);
      dispatch(setPaperType(store.userPage.paperType));
    }
  }, [dispatch, store.userPage]);

  return (
    <>
      <div
        className={`card-body ${smLayout.paperType}`}
        style={{ minHeight: "100%" }}
        ref={componentRef}
      >
        {size && (
          <Sketch
            ref={ref}
            width={size.width}
            height={size.height}
            setTool={setTool}
            dispatch={dispatch}
            onPositionChange={() => new Function()}
          />
        )}
      </div>
    </>
  );
};

export default BlankPage;
