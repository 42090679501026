/* eslint-disable no-new-func */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/destructuring-assignment */
import { render } from '@testing-library/react';
import WinBox from 'react-winbox';
import { Button } from 'reactstrap';
import './custom/icons.scss'

const Window = (props) => {
  const {
    title,
    noMin,
    noDelete,
    width,
    height,
    noMax,
    noFull,
    noResize,
    onResize,
    onClose,
    onDelete
  } = props;

  const DeleteElement  = () => {
  }
  return (
    <WinBox
      className={`white ${ onDelete === undefined ?'no-delete': ''}`}
      title={title}
      border={4}
      width={width !== undefined ? width : 600}
      height={height !== undefined ? height : 200}
      x="center"
      noMin={noMin !== undefined ? noMin : false}
      noMax={noMax !== undefined ? noMax : false}
      noFull={noFull !== undefined ? noFull : false}
      noResize={noResize !== undefined ? noResize : false}
      noDelete= {noDelete !== undefined ? noDelete : false}
      y={0}
      max={false}
      top={0}
      right={0}
      bottom={0}
      left={150}
      onDelete= {onDelete}
      onclose={() => onClose()}
      onresize={(width, height) =>
        onResize !== undefined ? onResize(width, height) : new Function()
      }
    >

      {props.children}

    </WinBox>
  );
};

export default Window;
