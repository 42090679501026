/* eslint no-unused-vars: 0 */

import { on } from 'events';
import FabricCanvasTool from '../fabrictool';

class Select extends FabricCanvasTool {
  configureCanvas(props) {
    const canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.forEachObject((o) => {
      if (o.hasOwnProperty('id')) {
        if (
          o.id.includes('gapFillingItemIcon') ||
          o.id.includes('gapFillingCloseIcon') ||
          o.id.includes('pageItem') ||
          o.id.includes('mediaItem') ||
          o.id.includes('gapFillingItem')
        )
          o.selectable = false;
      } else {
        o.selectable = o.evented = true;
      }
    });
  }
}

export default Select;
