import { useEffect, useRef, useState } from "react";
import * as Icon from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import Sketch from "./Sketch";
import ShowMediaItem from "./components/MediaItems/ShowMediaItem";
import {
  setUserItems,
  setPageItem,
  setCurrentPage,
  setCurrentPageItems,
  getBookPage,
  setContentInfo,
} from "./store";
import { useResize } from "../../utility/hooks/useResize";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { getPageImage } from "../../data/IonicStorage";
import { getPageItems } from "../../database/bookDatabaseService";

const BookPage = ({ setBlock, block }) => {
  const componentRef = useRef();
  const size = useResize(componentRef)
  const ref = useRef();
  const smartBookLayout = useSelector((state) => state.smartBookLayout);
  const smartBookStore = useSelector((state) => state.smartBookStore);
  const { currentPage } = smartBookStore;
  const dispatch = useDispatch();

  const showUserItem = (item) => {
    let items = [...smartBookStore.userItems];
    items = items.map((p) => (p.id === item.id ? { ...p, IsOpen: true } : p));
    dispatch(setUserItems(items));
  };

  const onQuestionClick = (id, item, type) => {
    if (type === "mediaItem") {
      showUserItem(item);
    } else {
      dispatch(setPageItem(id));
    }
  };

  const setPageImage = (re) => {
    if (size) {
      ref.current.setBackgroundFromDataUrl(
        re.pageImage,
        {
          isBottomToolOpen: smartBookLayout.isBottomToolOpen,
          isFullWidth: smartBookLayout.isPageFullWidth,
          pageItems: re.pageItems,
          userItems: smartBookStore.userItems.filter(
            (x) => x.pageNumber === currentPage.pageNo
          ),
          onQuestionClick,
        },
        true
      );
      ref.current.clear();
    }
  };
  useEffect(() => {
    // setBlock(true);
    const json = localStorage.getItem(`pagedrawings-${currentPage.id}`);
    // if (json) {
    //   ref.current.fromJSON(json, currentPage.pageImage, {
    //     sketchHeight: height,
    //     sketchWidth: width,
    //     isFullWidth,
    //     pageItems: currentPage.pageItems,
    //     onQuestionClick
    //   });
    // } else {
    if (
      currentPage &&
      currentPage.id !== smartBookStore.currentPageItems.pageId
    ) {
      const readSecretFile = async () => {
        try {
          const pageImage = await getPageImage(
            smartBookStore.currentPage.bookId,
            smartBookStore.currentPage.id
          );
          const pageItems = await getPageItems(smartBookStore.currentPage.id);
          dispatch(
            setCurrentPageItems({
              pageItems,
              pageImage,
              pageId: smartBookStore.currentPage.id,
            })
          );
          return { pageImage, pageItems };
        } catch (e) {
          console.log(e);
        }
      };
      readSecretFile().then((re) => {
        setPageImage(re);
      });
    } else {
      setPageImage({
        pageImage: smartBookStore.currentPageItems.pageImage,
        pageItems: smartBookStore.currentPageItems.pageItems,
      });
    }
    dispatch(setContentInfo(currentPage.pageNo))
  }, [
    currentPage,
    smartBookLayout.isPageFullWidth,
    smartBookStore.userItems,
    smartBookLayout.isBottomToolOpen,
    size
  ]);

  const loadUserItems = () => {
    {
      return smartBookStore.userItems
        .filter((x) => x.pageNumber === currentPage.pageNo && x.IsOpen === true)
        .map((item, index) => {
          return (
            // eslint-disable-next-line no-mixed-operators
            <ShowMediaItem
              key={`$userItem-${item.id}`}
              item={item}
              mediaItems={smartBookStore.userItems}
              dispatch={dispatch}
              setUserItems={setUserItems}
              bookId={smartBookStore.book.id}
              smartBookLayout={smartBookLayout}
              scale={0.4}
              color={"#e34041"}
              onClick={() => showUserItem(item)}
              Icon={Icon["FaSearch"]}
            />
          );
        });
    }
  };
  return (
    <div
      className="card-body p-0 w-100 h-100 bookPage"
      style={{ minHeight: "100%" }}
      ref={componentRef}
    >
      {size && (
        <Sketch
          ref={ref}
          width={size.width}
          height={size.height}
          background='url("https://media.istockphoto.com/id/1320330053/photo/dotted-grid-paper-background-texture-seamless-repeat-pattern.jpg?b=1&s=170667a&w=0&k=20&c=7J_y56bPBPGszpBcafqeuO-F6JAoRxY6XUfABSskbJE=")'
          dispatch={dispatch}
          displayType="pagedrawings"
          itemId={smartBookStore.currentPage.id}
        >
          {/* {pagePosition && !block && loadOptions()} */}
          {!block && loadUserItems()}
        </Sketch>
      )}
    </div>
  );
};

export default BookPage;
