// ** Third Party Components
import classnames from "classnames";

// ** Reactstrap Imports
import { CardBody, Card } from "reactstrap";
import { FaRegBookmark, FaBook, FaFile } from "react-icons/fa";
import { IoLibrary } from "react-icons/io5";
import { MdLiveHelp } from "react-icons/md";
import LeftSideHeader from "./LeftSideHeader";
import SearchPage from "./SearhPage";
import { useState } from "react";
import "./index.scss";
import BookContents from "./BookContents";
import SbSettings from "./Settings";
import UserPages from "./UserPages";
import { useHistory } from "react-router";
import { getBookPage, setCurrentPage, setDisplayType,setUserPage, setUserSettings } from "../store";
const LeftSidebar = (props) => {
  // ** Props
  const { sidebarOpen, dispatch, sbStore, smLayout, setSetting } = props;
  const [isSearchPageOpen, setSearchPageOpen] = useState();
  const [isBookContentOpen, setBookContentIsOpen] = useState();
  const [isSettingPageOpen, setSettingPageIsOpen] = useState();
  const [isUserPagesOpen, setIsUserPagesOpen] = useState();
  const history = useHistory();
  const openBookContent = () => {};
  return (
    <>
      <div
        className={classnames("smartbook-sidebar-left", {
          show: sidebarOpen,
        })}
      >
        <div className="sidebar sm-left-menu">
          <div className="sidebar-content smartbook-sidebar">
            <div className="smartbook-side-menu">
              <div className="form-group-compose text-center compose-btn">
                <LeftSideHeader></LeftSideHeader>
              </div>
              <ul className="sb-navigation mt-1 p-0">
                <li className="nav-item">
                  <Card
                    className="icon-card cursor-pointer text-center mb-0 mx-50 card-1"
                    onClick={() => history.push('/books')}
                  >
                    <CardBody>
                      <div className="icon-wrapper">
                        <IoLibrary size={20}></IoLibrary>
                      </div>
                      <h6 className="transaction-title">Kitaplar</h6>
                    </CardBody>
                  </Card>
                </li>
                <li className="nav-item">
                  <Card
                    className="icon-card cursor-pointer text-center mb-0 mx-50 card-2"
                    onClick={() => setBookContentIsOpen(true)}
                  >
                    <CardBody>
                      <div className="icon-wrapper">
                        <FaRegBookmark size={20} />
                      </div>
                      <h6 className="transaction-title">İçindekiler</h6>
                    </CardBody>
                  </Card>
                </li>
                <li className="nav-item">
                  <Card
                    className="icon-card cursor-pointer text-center mb-0 mx-50 card-3"
                    onClick={() => setIsUserPagesOpen(true)}
                  >
                    <CardBody>
                      <div className="icon-wrapper">
                        <FaFile size={20} />
                      </div>
                      <h6 className="transaction-title">Boş Sayfa</h6>
                    </CardBody>
                  </Card>
                </li>
                <li className="nav-item">
                  <Card
                    className="icon-card cursor-pointer text-center mb-0 mx-50 card-4"
                    onClick={() => setSettingPageIsOpen(true)}
                  >
                    <CardBody>
                      <div className="icon-wrapper">
                        <MdLiveHelp size={20} />
                      </div>
                      <h6 className="transaction-title">Ayarlar</h6>
                    </CardBody>
                  </Card>
                </li>
                <li className="nav-item">
                  <Card
                    className="icon-card cursor-pointer text-center mb-0 mx-50 card-4"
                    onClick={() => setSearchPageOpen(true)}
                  >
                    <CardBody>
                      <div className="icon-wrapper">
                        <MdLiveHelp size={20} />
                      </div>
                      <h6 className="transaction-title">Ara</h6>
                    </CardBody>
                  </Card>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isSearchPageOpen && (
        <SearchPage
          isOpen={isSearchPageOpen}
          openSearchPage={() => setSearchPageOpen(!isSearchPageOpen)}
        />
      )}
      {isBookContentOpen && (
        <BookContents
          isOpen={isBookContentOpen}
          dispatch={dispatch}
          sbStore={sbStore}
          setCurrentPage= {setCurrentPage}
          openBookContent={() => setBookContentIsOpen(!isBookContentOpen)}
        />
      )}
      {isUserPagesOpen && (
        <UserPages
          isOpen={isUserPagesOpen}
          setUserPage={setUserPage}
          setDisplayType={setDisplayType}
          dispatch={dispatch}
          sbStore={sbStore}
          openBookContent={() => setIsUserPagesOpen(!isUserPagesOpen)}
        />
      )}
      {isSettingPageOpen && (
        <SbSettings
          isOpen={isSettingPageOpen}
          dispatch={dispatch}
          setUserSettings={setUserSettings}
          store={smLayout}
          sbStore={sbStore}
          openSettings={() => setSettingPageIsOpen(!isSettingPageOpen)}
        />
      )}
    </>
  );
};

export default LeftSidebar;
