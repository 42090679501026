
import classnames from 'classnames';
import { Settings, X } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';

// ** Styles

const BookContents = (props) => {
  // ** Props
  const { isOpen, sbStore, dispatch, setCurrentPage, openBookContent } = props;

  const bookContents = sbStore.book.units;
  const handleToggle = () => {
    openBookContent(!isOpen);
  };

  const navigatePage = (bookId, pageNumber) => {
    const page = sbStore.book.pages.find(p=>p.pageNo===pageNumber);
    if(page){
      dispatch(setCurrentPage(page));
    }
    handleToggle()
  };

  return (
    <div
      className={classnames('side-contents d-md-block', {
        open: isOpen,
      })}
    >
  
        <div className="book-contents-header px-2 pt-1 pb-0 position-relative">
          <h4 className="mb-0">İçindekiler</h4>
          <button
            className="book-contents-close"
            onClick={() => handleToggle()}
          >
            <X />
          </button>
        </div>
        <hr />
        <ol className="toc-list" role="list">
          {bookContents &&
            bookContents.map((unit, index) => {
              return (
                <li key={`$unit-${index}`} onClick={() => navigatePage(sbStore.book.id, unit.startPage)}>
                  <span>
                    <span className="title">
                      {unit.name}
                      <span className="leaders" aria-hidden="true"></span>
                    </span>
                    <span className="page">
                      <span className="visually-hidden"></span> {unit.startPage}
                    </span>
                  </span>
                  <ol role="list">
                    {unit.unitContents &&
                      unit.unitContents.map((unitContent, index) => {
                        return (
                          <li key={`unitContent-${index}`} onClick={() => navigatePage(sbStore.book.id, unitContent.startPage)}>
                            <span>
                              <span className="title">
                                {unitContent.name}
                                <span
                                  className="leaders"
                                  aria-hidden="true"
                                ></span>
                              </span>
                              <span className="page">
                                <span className="visually-hidden"></span>
                                {unitContent.startPage}
                              </span>
                            </span>
                          </li>
                        );
                      })}
                  </ol>
                </li>
              );
            })}
        </ol>
    </div>
  );
};

export default BookContents;
