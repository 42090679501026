// ** Third Party Components
import classnames from "classnames";

// ** Reactstrap Imports
import { CardBody, Button } from "reactstrap";
import { FaRegBookmark, FaBook, FaFile } from "react-icons/fa";
import { IoLibrary } from "react-icons/io5";
import { MdLiveHelp } from "react-icons/md";
import LeftSideHeader from "./LeftSideHeader";
import { setCurrentPage } from "../store";
import SearchPage from "./SearhPage";
import { useState } from "react";
import "./index.scss";
import BookContents from "./BookContents";
import SbSettings from "./Settings";
import { useHistory } from "react-router";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  chevronDownCircle,
  chevronForwardCircle,
  chevronUpCircle,
  colorPalette,
  document,
  globe,
} from "ionicons/icons";
const LeftSideMobile = (props) => {
  // ** Props
  const { sidebarOpen, dispatch, sbStore, smLayout, setUserSettings } = props;
  const [isSearchPageOpen, setSearchPageOpen] = useState();
  const [isBookContentOpen, setBookContentIsOpen] = useState();
  const [isSettingPageOpen, setSettingPageIsOpen] = useState();
  const history = useHistory();
  const openBookContent = () => {};
  return (
    <>
      <IonFab
        slot="fixed"
        vertical="bottom"
        defaultChecked={true}
        horizontal="start"
        className="sidebar-mobile"
      >
        <IonFabButton>
          <IonIcon icon={chevronUpCircle}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <div className="menu-btn btn-5 mt-25" onClick={() => setSearchPageOpen(true)}>Ara</div>
          <div className="menu-btn btn-4 mt-25" onClick={() => setSettingPageIsOpen(true)}>Ayarlar</div>
          <div className="menu-btn btn-3 mt-25" onClick={() => setBookContentIsOpen(true)}>
            Kayıtlı Sayfalar
          </div>
          <div className="menu-btn btn-2 mt-25" onClick={() => setBookContentIsOpen(true)}>
            İçindekiler
          </div>
          <div className="menu-btn btn-1 mt-25" onClick={() => history.push("/books")}>Kitaplar</div>
        </IonFabList>
      </IonFab>
      {isSearchPageOpen && (
        <SearchPage
          isOpen={isSearchPageOpen}
          openSearchPage={() => setSearchPageOpen(!isSearchPageOpen)}
        />
      )}
      {isBookContentOpen && (
        <BookContents
          isOpen={isBookContentOpen}
          setCurrentPage={setCurrentPage}
          dispatch={dispatch}
          sbStore={sbStore}
          openBookContent={() => setBookContentIsOpen(!isBookContentOpen)}
        />
      )}
      {isSettingPageOpen && (
        <SbSettings
          isOpen={isSettingPageOpen}
          dispatch={dispatch}
          setUserSettings={setUserSettings}
          sbStore={sbStore}
          openSettings={() => setSettingPageIsOpen(!isSettingPageOpen)}
        />
      )}
    </>
  );
};

export default LeftSideMobile;
