// ** Reducers Imports

import authentication from './authentication'
import bookStore from '../pages/Books/store/index'
import smartBookLayout from './smartBookLayout'
import smartBookStore from '../pages/SmartBook/store/index'
const rootReducer = {
  authentication,
  bookStore,
  smartBookLayout,
  smartBookStore
}

export default rootReducer
