
import reactCSS from 'reactcss';

const ColorItem = ({ index, color, openColorPicker }) => {
  const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: color,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });
  return (
    <div
      className='me-1'
      style={styles.swatch}
      onClick={() => openColorPicker(index, color)}
    >
      <div style={styles.color} />
    </div>
  );
};
export default ColorItem;
