import { useEffect, useRef, useState } from "react";
import Sketch from "./Sketch";
import { useResize } from "../../utility/hooks/useResize";
import { useDispatch, useSelector } from "react-redux";
import VideoWindow from "./components/MediaItems/VideoWindow";
import ImageWindow from "./components/MediaItems/ImageWindow";

const Slayt = ({ pageItemPosition }) => {
  const componentRef = useRef();
  const ref = useRef();
  const size = useResize(componentRef);
  const [showRootImage, setShowRootImage] = useState(true);
  const [isImageSolutionOpen, setShowImageSolution] = useState(false);
  const [isVideoSolutionOpen, setShowVideoSolution] = useState(false);
  const dispatch = useDispatch();

  const smartBookLayout = useSelector((state) => state.smartBookLayout);
  const smartBookStore = useSelector((state) => state.smartBookStore);

  const showImageSolution = () => {
    setShowImageSolution(!isImageSolutionOpen);
  };
  const showVideoSolution = () => {
    setShowVideoSolution(!isVideoSolutionOpen);
  };

  useEffect(() => {
    if (smartBookStore.currentPageItem && size) {
      ref.current.cropImage(
        smartBookStore.currentPageItems.pageImage,
        {
          sketchHeight: size.height,
          sketchWidth: size.width,
          pageItem: smartBookStore.currentPageItem,
          pageItemPosition,
          pageItemIsFull: smartBookLayout.pageItemIsFull,
          showRootImage: false,
          showImageSolution,
          showVideoSolution,
        },
        true
      );
      ref.current.clear();
    }
    setShowImageSolution(false);
    setShowVideoSolution(false);
  }, [
    smartBookStore.currentPageItem,
    size,
    pageItemPosition,
    smartBookLayout.pageItemIsFull,
    showRootImage,
  ]);

  // const nextPageItem = () => {
  //   const currentIndex = store.currentPage.pageItems.findIndex(
  //     (x) => x.id === store.currentPageItem.id
  //   );
  //   if (store.currentPage.pageItems[currentIndex + 1]) {
  //     dispatch(setPageItem(currentIndex + 1));
  //   } else {
  //     dispatch(
  //       getBookPage({
  //         bookId: store.book.id,
  //         pageNumber: store.currentPage.pageNo + 1,
  //         isNext: true,
  //       })
  //       // eslint-disable-next-line promise/always-return
  //     ).then(() => {
  //       console.log(store.currentPage.pageNo);
  //     });
  //     setIsNext(true);
  //   }
  // };
  // const previousPageItem = () => {
  //   const currentIndex = store.currentPage.pageItems.findIndex(
  //     (x) => x.id === store.currentPageItem.id
  //   );
  //   if (store.currentPage.pageItems[currentIndex - 1]) {
  //     dispatch(setPageItem(currentIndex - 1));
  //   } else if (store.currentPage.pageNo !== 1) {
  //     dispatch(
  //       getBookPage({
  //         bookId: store.book.id,
  //         pageNumber: store.currentPage.pageNo - 1,
  //         isNext: false,
  //       })
  //     );
  //     setIsNext(false);
  //   }
  // };

  return (
    <>
      <div
        className="card-body p-0"
        style={{ minHeight: "100%" }}
        ref={componentRef}
      >
        {/* <div className="h_iframe">
            <iframe
              title="Banner preview"
              src="C:\Users\omerk\Desktop\t1\index.html"
              frameBorder="0"
              allowfullscreen
             />
          </div> */}
        {size && (
          <>
            <Sketch
              ref={ref}
              width={size.width}
              height={size.height}
              background="white"
              dispatch={dispatch}
              display="pageitem"
              itemId={smartBookStore.currentPageItem.id}
            />
            {isImageSolutionOpen &&
              smartBookStore.currentPageItem.solutionImage && (
                <ImageWindow
                  title={`${smartBookStore.currentPageItem.title}-Çözüm`}
                  onWindowClose={() => setShowImageSolution(false)}
                  filePath={smartBookStore.currentPageItem.solutionImage}
                />
              )}
            {isVideoSolutionOpen &&
              smartBookStore.currentPageItem.solutionVideo && (
                <VideoWindow
                  title={`${smartBookStore.currentPageItem.title}-Çözüm`}
                  onWindowClose={() => setShowVideoSolution(false)}
                  filePath={smartBookStore.currentPageItem.solutionVideo}
                />
              )}
          </>
        )}
      </div>
    </>
  );
};

export default Slayt;
