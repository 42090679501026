

import FabricCanvasTool from "../fabrictool"

const fabric = require("fabric").fabric

class Pan extends FabricCanvasTool {
  configureCanvas(props) {
    const canvas = this._canvas
    canvas.isDrawingMode = canvas.selection = false
    // eslint-disable-next-line no-return-assign
    canvas.forEachObject((obj) =>{
      if(obj.id==null || (!obj.id.includes("pageItem") && !obj.id.includes("gapFillingItem"))){
        obj.selectable = obj.evented = false
      }
    });
    canvas.defaultCursor = "move"
  }

  doMouseDown(o) {
    const canvas = this._canvas
    this.isDown = true
    const pointer = canvas.getPointer(o.e)
    this.startX = pointer.x
    this.startY = pointer.y
  }

  doMouseMove(o) {
    if (!this.isDown) return
    const canvas = this._canvas
    const pointer = canvas.getPointer(o.e)
    canvas.relativePan({
      x: pointer.x - this.startX,
      y: pointer.y - this.startY
    })
    canvas.renderAll()
  }

  doMouseUp(o) {
    this.isDown = false
  }
}

export default Pan
