import React from "react";
import { IonFab, IonFabButton, IonFabList, IonIcon } from "@ionic/react";
import classnames from "classnames";
import { Tools } from "../../../@corefabriccanvas";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setTool, setIsMobileToolActive } from "../../../redux/smartBookLayout";
import {
  FaHandPaper,
  FaPen,
  FaMarker,
  FaFont,
  FaShapes,
  FaEraser,
  FaTrash

} from "react-icons/fa";
function MobileToolBox({ canvas, addText }) {
  const smartBookLayout = useSelector((state) => state.smartBookLayout);
  const dispatch = useDispatch();

  return (
    <div className="">
      <IonFab
       slot="fixed"
        vertical="bottom"
        activated={smartBookLayout.isMobileToolActive}
        horizontal="end"
        className="sidebar-mobile"
      >
        <IonFabButton
          color="secondary"
          activated={smartBookLayout.isMobileToolActive}
          onClick={() => {
            dispatch(
              setIsMobileToolActive(!smartBookLayout.isMobileToolActive)
            );
            this.onClick();
          }}
        >
          {smartBookLayout.currentTool === Tools.Eraser && <FaEraser />}
          {smartBookLayout.currentTool === Tools.Pencil && <FaPen />}
          {smartBookLayout.currentTool === Tools.Pan && <FaHandPaper />}
          {smartBookLayout.currentTool === Tools.Marker && <FaMarker />}
          {smartBookLayout.currentTool === Tools.Text && <FaFont />}
        </IonFabButton>
        <IonFabList side="top">
          {/* <button
            className={classnames("round mt-25 btn btn-md", {
              active: smartBookLayout.currentTool === Tools.Text,
            })}
          >
            <FaFont size={20} />
          </button> */}
          <button
            className="round mt-25 btn btn-md"
            size="md"
            onClick={() => canvas.clear()}
          >
            <FaTrash size={20} />
          </button>
          <button className="round mt-25" size="md">
            <FaShapes size={20} />
          </button>
          <button
            className={classnames("round mt-25", {
              "active-tool": smartBookLayout.currentTool === Tools.Marker,
            })}
            size="md"
            onClick={() => dispatch(setTool(Tools.Marker))}
          >
            <FaMarker size={20} />
          </button>
          <button
            className={classnames("round mt-25", {
              "active-tool": smartBookLayout.currentTool === Tools.Eraser,
            })}
            size="md"
            onClick={() => dispatch(setTool(Tools.Eraser))}
          >
            <FaEraser size={20} />
          </button>
          <button
            className={classnames("round mt-25", {
              "active-tool": smartBookLayout.currentTool === Tools.Pencil,
            })}
            size="md"
            onClick={() => dispatch(setTool(Tools.Pencil))}
          >
            <FaPen size={20} />
          </button>
          <button
            className={classnames("round mt-25", {
              "active-tool": smartBookLayout.currentTool === Tools.Pan,
            })}
            size="md"
            onClick={() => dispatch(setTool(Tools.Pan))}
          >
            <FaHandPaper size={20} />
          </button>
        </IonFabList>
      </IonFab>
    </div>
  );
}
export default MobileToolBox;
