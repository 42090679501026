/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/media-has-caption */
import { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import Window from '../../../../../components/Window';
const PixeBayVideo = ({ item }) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <>
      <Card onClick={() => setShowVideo(!showVideo)}>
        <CardBody className="text-center">
          <video width="320" height="240" controls>
            <source src={item.videos.small.url} type="video/mp4" />
          </video>
        </CardBody>
      </Card>
      {showVideo && (
        <Window
          title="Video"
          width={900}
          noDelete
          height={400}
          noMax
          onClose={() => console.log()}
          onResize={(width, height) => console.log()}
        >
          <video controls>
            <source src={item.videos.small.url} type="video/mp4" />
          </video>
        </Window>
      )}
    </>
  );
};

export default PixeBayVideo;
