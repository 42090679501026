/* eslint-disable react/function-component-definition */
// ** Third Party Components
import classnames from 'classnames'

// ** Reactstrap Imports
import { Spinner } from 'reactstrap'

// ** Styles
import './ui-loader.scss'

const UILoader = props => {
  const { children, blocking, loader, className, tag, overlayColor } = props

  const Tag = tag

  return (
    <Tag className={classnames('ui-loader', { [className]: className, show: blocking })}>
      {children}
      {blocking ? (
        <>
          <div
            className='overlay' /*eslint-disable */
            {...(blocking && overlayColor ? { style: { backgroundColor: overlayColor } } : {})}
            /* eslint-enable */
           />
          <div className='loader'>{loader}</div>
        </>
      ) : null}
    </Tag>
  )
}

export default UILoader

UILoader.defaultProps = {
  tag: 'div',
  // eslint-disable-next-line react/default-props-match-prop-types
  blocking: false,
  loader: <Spinner color='primary' />
}
