
import Window from '../../../../components/Window';
import ReactAudioPlayer from 'react-audio-player';

const SoundWindow = (props) => {
  const { filePath, id, deleteUserItem, onWindowClose } = props;
  const onResize = () => {};
  return (
    <>
      <Window
        title="Ses"
        width={700}
        noDelete
        height={400}
        noMax
        onClose={() => onWindowClose()}
        onResize={(width, height) => onResize(width, height)}
        onDelete= {()=> deleteUserItem(id)}
      >
          <ReactAudioPlayer
            className='w-100'
            src={filePath}
            controls
           />
      </Window>
    </>
  );
};

export default SoundWindow;
