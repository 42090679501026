
import { useState, Fragment } from 'react';

// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

// ** Third Party Imports
import { useDropzone } from 'react-dropzone';
import { FileText, X, DownloadCloud } from 'react-feather';

const FileUploaderSingle = ({
  type,
  axisX,
  axisY,
  bookId,
  pageNumber,
  setShowAddContentWindow,
  onUserItemSave
}) => {
  // ** State
  const [files, setFiles] = useState([]);
  let acceptedFiles = {};

  if (type === "40") {
    acceptedFiles = {
      'audio/*': [],
    };
  }
  if (type === "30") {
    acceptedFiles = {
      'image/*': [],
    };
  }
  if (type === "50") {
    acceptedFiles = {
      'video/*': [],
    };
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: acceptedFiles,
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles.map((file) => Object.assign(file))]);
    },
  });
  const renderFilePreview = (file) => {
    if (file.type.startsWith('image')) {
      return (
        <img
          className="rounded"
          alt={file.name}
          src={URL.createObjectURL(file)}
          height="28"
          width="28"
        />
      );
    }
    return <FileText size="28" />;
  };
  async function handleUploadFile() {
    const formData = new FormData();
    formData.append('name', files[0].name);
    formData.append('formFile', files[0]);
    formData.append('encrypted', true);
    const temporaryURL = URL.createObjectURL(files[0]);
    const blob = await fetch(temporaryURL).then((r) => r.arrayBuffer());
    const fileData = new Int8Array(blob);
    const fileExtension = files[0].type.split('/')[1];
    // fs.writeFile("filepath.png", fileData);
    window.electron
      .uploadFile(fileData, axisX, axisY, bookId, pageNumber, fileExtension, null, type)
      .then(() => {
        setShowAddContentWindow(false);
        onUserItemSave()
      });
  }

  const handleRemoveFile = (file) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i) => i.name !== file.name);
    setFiles([...filtered]);
  };

  const renderFileSize = (size) => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`;
    }
    return `${(Math.round(size / 100) / 10).toFixed(1)} kb`;
  };

  const fileList = files.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
          <p className="file-size mb-0">{renderFileSize(file.size)}</p>
        </div>
      </div>
      <Button
        color="danger"
        outline
        size="sm"
        className="btn-icon"
        onClick={() => handleRemoveFile(file)}
      >
        <X size={14} />
      </Button>
    </ListGroupItem>
  ));

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Dosya Yükle</CardTitle>
      </CardHeader>
      <CardBody>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="d-flex align-items-center justify-content-center flex-column">
            <DownloadCloud size={64} />
            <h5>Eklemek İstediğiniz Dosyaları Seçin ve Yükle Butonuna Basın</h5>
            <p className="text-secondary">
              Dosyaları buraya sürükleyin veya dosya seçmek için
              <a href="/" onClick={(e) => e.preventDefault()}>
                tıklayın
              </a>{' '}
            </p>
          </div>
        </div>
        {files.length ? (
          <>
            <ListGroup className="my-2">{fileList}</ListGroup>
            <div className="d-flex justify-content-end">
              <Button
                className="me-1"
                color="danger"
                outline
                onClick={handleRemoveAllFiles}
              >
                Kaldır
              </Button>
              <Button color="primary" onClick={handleUploadFile}>
                Yükle
              </Button>
            </div>
          </>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default FileUploaderSingle;
