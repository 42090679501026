
import { useEffect, useState } from "react"
import { Spinner } from "reactstrap"
import { Book } from "react-feather"

const AsyncImage = (props) => {
  const [imagesrc, setImageSrc] = useState(null)

  useEffect(() => {
    setImageSrc(props.src)
    // call the function
  }, [props])
  return (
    <>
      {imagesrc !== null && imagesrc.length > 0 ? (
        <img
          alt=""
          className="img-fluid"
          src={`data:image/png;base64,${imagesrc}`}
          width={props.width}
          height={props.height}
          max-width={props.maxWidth}
          max-height={props.maxHeight}
         />
      ) : (
        imagesrc === null ? <Spinner type="grow" color="primary" /> : <><Book size={50} /></>
      )}
    </>
  )
}

export default AsyncImage
