import {
  FaArrowLeft,
  FaArrowRight,
  FaTimes,
  FaBuromobelexperte,
} from 'react-icons/fa';
import {
  AiOutlineAlignCenter,
  AiOutlineAlignRight,
  AiOutlineAlignLeft,
} from 'react-icons/ai';
import {
  BsFullscreenExit,
  BsFullscreen,
  BsQuestionDiamond,
} from 'react-icons/bs';
import { useEffect, useState } from 'react';
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';

const TestTool = (props) => {
  const {
    store,
    layoutStore,
    dispatch,
    setCurrentTest,
    handlePageItemSize,
    handlePageItemPosition,
    getBookPage,
    setCurrentPageItem,
    showAllGapFillingAnswer,
  } = props;

  const [showOpticalForm, setShowOpticalForm] = useState();
  const [showGapFillingAnswer, setShowGapFillingAnswer] = useState(true);
  useEffect(() => {
    if (store.currentPageItem.contentId !== store.currentTest.id) {
      setShowOpticalForm(false);
    }
    if (store.currentPageItem.pageItemType === 0) {
      if (
        store.currentTest.id !== store.currentPageItem.contentId ||
        store.currentTest
      ) {
        // window.electron
        //   .getTestQuestions(store.currentPageItem.contentId)
        //   .then((res) => {
        //     const currentTest = {
        //       id: store.currentPageItem.contentId,
        //       questions: [],
        //     };
        //     res.map((question) => {
        //       return currentTest.questions.push({
        //         id: question.id,
        //         title: question.title,
        //         correctAnswer: question.correctAnswer,
        //         options: question.options,
        //         userAnswer: '',
        //       });
        //     });
        //     dispatch(setCurrentTest(currentTest));
        //   });
      }
    }
  }, [store.currentPageItem]);

  const handleUserAnswer = (choice, questionId) => {
    let tempTest = store.currentTest;
    tempTest = {
      ...tempTest,
      questions: tempTest.questions.map((p) =>
        p.id === questionId ? { ...p, userAnswer: choice } : p
      ),
    };
    dispatch(setCurrentTest(tempTest));

    if (store.currentPageItem.id !== questionId) {
      // window.electron.getQuestion(questionId).then((res) => {
      //   if (res.pageNo !== store.currentPage.pageNo) {
      //     dispatch(
      //       getBookPage({
      //         bookId: store.book.id,
      //         pageNumber: res.pageNo,
      //       })
      //     ).then(() => {
      //       dispatch(setCurrentPageItem(res));
      //     });
      //   } else {
      //     dispatch(setCurrentPageItem(res));
      //   }
      // });
    }
  };

  const loadOptions = () => {
    return store.currentPageItem.options.map((option, index) => {
      return (
        <div key={`option-${index}`} className="option ms-1">
          <input
            type="radio"
            id={`option-${option.id}`}
            name={option.id}
            value={option.title}
            onChange={() =>
              handleUserAnswer(option.title, store.currentPageItem.id)
            }
            checked={
              store.currentTest.questions &&
              store.currentPageItem.contentId === store.currentTest.id
                ? store.currentTest.questions.find(
                    (x) => x.id === store.currentPageItem.id
                  ).userAnswer === option.title
                : false
            }
          />
          <label className="text-white" htmlFor={`option-${option.id}`}>
            {' '}
            {option.title}{' '}
          </label>
        </div>
      );
    });
  };
  return (
    <>
      <div
        className="tool-item"
        onClick={() =>
          dispatch(handlePageItemSize(!layoutStore.pageItemIsFull))
        }
      >
        {layoutStore.pageItemIsFull ? <BsFullscreenExit /> : <BsFullscreen />}
      </div>
      <div
        className="tool-item"
        onClick={() => dispatch(handlePageItemPosition('left'))}
      >
        <AiOutlineAlignLeft />
      </div>
      <div
        className="tool-item"
        onClick={() => dispatch(handlePageItemPosition('center'))}
      >
        <AiOutlineAlignCenter />
      </div>
      <div
        className="tool-item"
        onClick={() => dispatch(handlePageItemPosition('right'))}
      >
        <AiOutlineAlignRight />
      </div>
      {store.currentPageItem.pageItemType === 0 && (
        <div
          className="tool-item"
          onClick={() => setShowOpticalForm(!showOpticalForm)}
        >
          <FaBuromobelexperte />
        </div>
      )}
      {store.currentPageItem.pageItemType === 0 && (
        <>
          <div className="tool-item" id="popTop">
            <BsQuestionDiamond />
          </div>
          <UncontrolledPopover placement="top" target="popTop">
            <PopoverHeader>
              {store.currentPageItem.displayOrder}. Soru
            </PopoverHeader>
            <PopoverBody>
              Doğru Cevap: {store.currentPageItem.correctAnswer}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      )}
      {store.currentPageItem.pageItemType === 20 && (
        <>
          <div
            className="tool-item"
            onClick={() => {
              showAllGapFillingAnswer(showGapFillingAnswer);
              setShowGapFillingAnswer(!showGapFillingAnswer);
            }}
          >
            <BsQuestionDiamond />
          </div>
        </>
      )}
      {store.currentPageItem.rootPageNo > 0 && (
        <>
          <div
            className="tool-item"
            id="popTop"
            onClick={() => {
              showAllGapFillingAnswer(showGapFillingAnswer);
              setShowGapFillingAnswer(!showGapFillingAnswer);
            }}
          >
            <BsQuestionDiamond />
          </div>
        </>
      )}
      {/* {store.currentPageItem.pageItemType === 0 && (
        <OpticalForm
          showOpticalForm={showOpticalForm}
          getBookPage={getBookPage}
          handleUserAnswer={handleUserAnswer}
          setShowOpticalForm={setShowOpticalForm}
          setCurrentPageItem={setCurrentPageItem}
          dispatch={dispatch}
          store={store}
          setCurrentTest={setCurrentTest}
        />
      )} */}
    </>
  );
};

export default TestTool;
