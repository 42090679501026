import SizeToolItem from './SizeToolItem'

const ThicknessTool = (props) => {
  const { dispatch, setThickness, store, className } = props
  return (
    <div className={`subTool ${className}`}>
      <div className='border-bottom'>
        <span className="text-dark">Boyut Seçimi:</span>
      </div>
      <ul className="list-inline unstyled-list">
        {store.thicknesses.map((size) => {
          return (
            <SizeToolItem
              onClick={() => dispatch(setThickness(parseInt(size.value, 10)))}
              size={parseInt(size.value, 10)}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default ThicknessTool
