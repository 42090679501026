// SQLITE IMPORTS
import {} from "@capacitor-community/sqlite";
// JSON FILE WITH DATA
import { sqlite } from "../App";

export const saveUser = async (userMail, userTitle, accessToken, refreshToken) => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const updateUsers = `Update users set isActive=0`;
    await db.query(updateUsers);
    const stmt = `INSERT INTO users (email,userTitle,accessToken,refreshToken, isActive) values ('${userMail}','${userTitle}', '${accessToken}', '${refreshToken}', 1) 
    ON CONFLICT(email) DO UPDATE SET accessToken='${accessToken}', refreshToken= '${refreshToken}', isActive=1`;
    const qValues = await db.query(stmt);
    await db.close();
    await sqlite.closeConnection("smartBook");
    return qValues;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};

export const getActiveUser = async() => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from users where isActive=1`;
    const qValues = await db.query(stmt);
    return qValues.values[0];
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
}
export const getAllUsers = async () => {
  let isConn = await sqlite.isConnection("smartBook");
  let db;
  try {
    if (!isConn.result) {
      db = await sqlite.createConnection("smartBook");
    } else {
      db = await sqlite.retrieveConnection("smartBook");
    }
    await db.open();
    // query the messages
    const stmt = `select * from users`;
    const qValues = await db.query(stmt);
    return qValues;
  } catch (err) {
    return Promise.reject(`Error: ${err}`);
  }
};
