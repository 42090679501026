import Window from "../../../../components/Window";
import FileUploaderSingle from "../FileUploader"
import './style.scss'

const AddContent = (props) => {
  const {
    type,
    name,
    selectedPosition,
    onNoteWindowClose,
    setShowAddContentWindow,
    bookId,
    pageNumber,
    onSave
  } = props;
  const onWindowClose = () => {
    onNoteWindowClose();
  };
  const onResize = () => {};
  return (
    <>
      <Window
        title={`${name} Ekle`}
        noMin
        noFull
        width={700}
        height={400}
        noMax
        onClose={() => onWindowClose()}
        onResize={(width, height) => onResize(width, height)}
      >
        <FileUploaderSingle
          type={type}
          axisX={selectedPosition.x}
          axisY={selectedPosition.y}
          setShowAddContentWindow={setShowAddContentWindow}
          onUserItemSave= {onSave}
          bookId={bookId}
          pageNumber={pageNumber}
        />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            alignItems: 'right',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        />
      </Window>
    </>
  );
};

export default AddContent;
