
const ColorToolItem = ({ color, onClick }) => {
  return (
    <li
      className="color-box border"
      style={{ backgroundColor: color }}
      onClick={() => onClick()}
     />
  )
}

export default ColorToolItem
