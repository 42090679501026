
import FabricCanvasTool from "../fabrictool"

const fabric = require("fabric").fabric

class Polygon extends FabricCanvasTool {
  configureCanvas(props) {
    const canvas = this._canvas
    canvas.isDrawingMode = canvas.selection = false
    // eslint-disable-next-line no-return-assign
    canvas.forEachObject((o) => (o.selectable = o.evented = false))
    this._width = props.lineWidth
    this._color = props.lineColor
    this._fill = props.fillColor
  }

  doMouseDown(o) {
    const canvas = this._canvas
    this.isDown = true
    const pointer = canvas.getPointer(o.e)
    this.startX = pointer.x
    this.startY = pointer.y
    this.polygon = new fabric.Polygon([
      {
        x: this.startX,
        y: this.startX
      },
      {
        x: this.startX + 100,
        y: this.startY + 100
      }
    ], {
      left: this.startX,
      top: this.startY,
      originX: "left",
      originY: "top"
    })
    canvas.add(this.polygon)
  }

  doMouseMove(o) {
    if (!this.isDown) return
    const canvas = this._canvas
    const pointer = canvas.getPointer(o.e)
    if (this.startX > pointer.x) {
      this.rect.set({ left: Math.abs(pointer.x) })
    }
    if (this.startY > pointer.y) {
      this.rect.set({ top: Math.abs(pointer.y) })
    }
    this.rect.set({ width: Math.abs(this.startX - pointer.x) })
    this.rect.set({ height: Math.abs(this.startY - pointer.y) })
    this.rect.setCoords()
    canvas.renderAll()
  }

  doMouseUp(o) {
    this.isDown = false
  }
}

export default Polygon
