
import Window from "../../../../components/Window";
const ImageWindow = (props) => {
  const { filePath, id, deleteUserItem, onWindowClose, title } = props;
  const onResize = () => {};
  return (
    <>
      <Window
        title={title || "Resim"}
        width={700}
        noDelete
        height={400}
        noMax
        onClose={() => onWindowClose()}
        onResize={(width, height) => onResize(width, height)}
        onDelete= {()=> deleteUserItem(id)}
      >
         <img
            className="img-fluid card-img-top mt-1"
            src={filePath}
          />
      </Window>
    </>
  );
};

export default ImageWindow;
